import React, { useEffect, useState } from "react";
import DashboardTiles from "./DashboardTiles";
import Topics from "./Topics";
import pluralize from "pluralize";

const DashboardView = ({
  onSubTopicChange,
  onTopicChange,
  onVizTypeChange,
  questionId,
  questions,
  subTopicId,
  topicId,
  topics,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = "School Health Profiles Explorer – Dashboard";
  }, []);

  function handleSearchChange(nextSearchTerm) {
    setSearchTerm(nextSearchTerm);
    //TODO: ADOBE-ANALYTICS: search term
  }

  const trimmedTerms = searchTerm && searchTerm.trim().split(/\s+/);
  const topicMemo = {};
  const filteredQuestions = questions.filter((q) => {
    if (topicId && q.TopicId !== topicId) return false;
    if (subTopicId && q.SubTopicId !== subTopicId) return false;

    const stringToSearch =
      q.ShortQuestionDescription +
      " " +
      q.LongQuestionDescription +
      " " +
      q.TopicDescription +
      " " +
      q.SubTopicDescription +
      " " +
      q.Keyword +
      " " +
      q.SubQuestions.map((q) => q.SubQuestion).join(" ");

    let include = 0;

    if (searchTerm && searchTerm.length > 0) {
      trimmedTerms &&
        trimmedTerms.forEach((term) => {
          const singular = pluralize.singular(term);
          const plural = pluralize.plural(term);
          const regex = new RegExp(singular + "|" + plural, "i");
          if (stringToSearch.match(regex)) {
            include++;
          }
        });

      if (trimmedTerms && include === trimmedTerms.length) {
        // Then every search term is present in some part of the stringToSearch
        topicMemo[q.TopicId] = true;
        return true;
      }
    } else {
      return true;
    }

    return false;
  });

  //lets hide the dashboard
  if (questionId) return "";
  return (
    <div
      className="row justify-content-center "
      role="navigation"
      id="dashboard"
      style={{ fontSize: ".75em" }}
    >
      <nav
        className="col-sm-12 col-md-3 d-md-block sidebar m-3"
        style={{
          border: "1px solid grey",
          borderRadius: "15px",
        }}
      >
        <div className="sidebar-sticky">
          <Topics
            onSearchChange={handleSearchChange}
            onTopicChange={onTopicChange}
            onSubTopicChange={onSubTopicChange}
            topicId={topicId}
            topics={topics}
          />
        </div>
      </nav>

      <div role="main" className="col-sm align-items-center pr-lg-0 pr-sm-0">
        <div className="row ml-0">
          <h1 className="tile-header">
            Explore School Health Profiles Questions - Nationwide, 2022
          </h1>
          <div className="tile-header2">
            Additional <b>locations</b> are available by selecting a question or
            subquestion below
          </div>
        </div>
        <hr />

        {questions && questions.length > 0 && (
          <DashboardTiles
            onVizTypeChange={onVizTypeChange}
            questions={filteredQuestions}
            searchTerm={searchTerm}
            topicId={topicId}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardView;
