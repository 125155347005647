import React from "react";
import { topicDictionary } from "../Util/tileDictionary";
import * as analytics from "../Util/Analytics/QuestionAnalytics";
const DataVisualizationToggle = ({
  onVizTypeChange,
  question,
  subQuestionValue,
  vizType,
}) => {
  function showMap() {
    analytics.selectMap();
    onVizTypeChange({
      vizType: "map",
      questionId: question.QuestionId,
      subQuestionId: subQuestionValue,
      locationAbbr: "ALL",
    });
  }

  function showTable() {
    analytics.selectTable();
    onVizTypeChange({
      vizType: "table",
      questionId: question.QuestionId,
      subQuestionId: subQuestionValue,
      locationAbbr: "ALL",
    });
  }

  return (
    <div className="sub-viz-type-toggle" style={{}}>
      <div className="col-sm-12 col-md-12 col-lg-6 p-0">
        <button
          tabIndex="0"
          onKeyDown={(e) => e.code === "Enter" && showTable()}
          className={`${
            vizType === "table" ? "selected-viz" : ""
          } filter-data-button btn btn-white removeRightBorderRadius p-1`}
          style={
            vizType === "table"
              ? {
                  backgroundColor: topicDictionary[question.TopicId].color,
                  color: "white",
                }
              : { color: topicDictionary[question.TopicId].color }
          }
          onClick={showTable}
        >
          <span className="cdc-icon-table-light"></span> Table
        </button>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 p-0">
        <button
          tabIndex="0"
          onKeyDown={(e) => e.code === "Enter" && showMap()}
          className={`${
            vizType === "map" ? "selected-viz" : ""
          } filter-data-button btn btn-white removeLeftBorderRadius p-1`}
          onClick={showMap}
          style={
            vizType === "map"
              ? {
                  backgroundColor: topicDictionary[question.TopicId].color,
                  color: "white",
                }
              : { color: topicDictionary[question.TopicId].color }
          }
        >
          <span className="cdc-icon-map_01"></span> Map
        </button>
      </div>
    </div>
  );
};

export default DataVisualizationToggle;
