import React, { useEffect, useState } from "react";
import Dropdown from "../Util/Dropdown";
import * as ENV from "../Util/env";

const YearDropdown = ({
  locationAbbr,
  onYearChange,
  questionId,
  subQuestionId,
  year,
}) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    fetch(
      ENV.APIBaseURL +
        "/Years?QuestionId=" +
        questionId +
        "&SubQuestionId=" +
        subQuestionId +
        "&LocationAbbr=" +
        locationAbbr
    )
      .then((res) => res.json())
      .then((data) => {
        setYears(data);
      });
  }, []);

  const additionalOptions = [];

  return (
    <>
      {years && years.length > 0 && (
        <Dropdown
          options={years}
          itemID="Year"
          itemName="Year"
          dropdownTitle="Year"
          dropdownID="Year"
          value={year}
          onChange={onYearChange}
          additionalOptions={additionalOptions}
          emptyText="Select a year"
          showEmpty={false}
        />
      )}
    </>
  );
};

export default YearDropdown;
