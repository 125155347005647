import axios from "axios";
import * as ENV from "../Util/env";
async function GetLocations() {
    try {
        return await axios.get(ENV.APIBaseURL + "/locations", {
            //headers: {
            //    'Cache-Control': 'max-age=180',
            //}
        });
    } catch (error) {
        console.log(error);
    }
}

async function GetTopics() {
    try {
        return await axios.get(ENV.APIBaseURL + "/Topics", {
            //headers: {
            //    'Cache-Control': 'max-age=180',
            //}
        });
    } catch (error) {
        console.log(error);
    }
}

async function GetQuestions() {
    try {
        return await axios.get(ENV.APIBaseURL + "/Questions", {
            //headers: {
            //    'Cache-Control': 'max-age=180',
            //}
        });
    } catch (error) {
        console.log(error);
    }
}

async function GetSubQuestions(subQuestionId, year) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/TableData/SubQuestions?QuestionId=" + subQuestionId +
            "&Year=" + year
        );
    } catch (error) {
        console.log(error);
    }
}

async function GetYears(questionId, subQuestionId, locationAbbr) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/Years?QuestionId=" +
            questionId +
            "&LocationAbbr=" +
            locationAbbr
        );
    } catch (error) {
        console.log(error);
    }
}

async function GetSubQuestionAllLocations({ subQuestionId, questionId, year }) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/TableData/SubQuestion/AllLocations?QuestionId=" +
            questionId +
            "&SubQuestionId=" +
            subQuestionId +
            "&year=" +
            year
        );
    } catch (error) {
        console.log(error);
    }
}

async function GetQuestionDataAllLocations({ questionId, year }) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/TableData/Question/AllLocations?QuestionId=" +
            questionId +
            "&year=" +
            year
        );
    } catch (error) {
        console.log(error);
    }
}

async function GetQuestionOneLocation({
    locationAbbr,
    questionId,
    year,
}) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/TableData/Question/OneLocation?QuestionId=" +
            questionId +
            "&LocationAbbr=" +
            locationAbbr +
            "&year=" +
            year
        );
    } catch (error) {
        console.log(error);
    }
}
async function GetPatternsOverTime({
    locationAbbr,
    questionId,
    subQuestionId,
}) {
    try {
        return await axios.get(
            ENV.APIBaseURL +
            "/TableData/PatternsOverTime?QuestionId=" +
            questionId +
            "&LocationAbbr=" +
            locationAbbr +
            "&SubQuestionId=" +
            subQuestionId
        );
    } catch (error) {
        console.log(error);
    }
}


export {
    GetLocations,
    GetTopics,
    GetQuestions,
    GetSubQuestions,
    GetYears,
    GetQuestionOneLocation,
    GetSubQuestionAllLocations,
    GetQuestionDataAllLocations,
    GetPatternsOverTime
};
