import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CDCHeader, CDCBody, CDCFooter } from "@cdcent/templatepackage-react";
import "@cdcent/templatepackage-react/assets/css/bootstrap.min.css";
import "@cdcent/templatepackage-react/assets/css/app.min.css";
import "./scss/main.scss";
import Dashboard from "./Components/Dashboard";
import DataVisualization from "./Components/DataVisualization";
import * as ENV from "./Util/env";
import * as analytics from "./Util/Analytics/SharedAnalytics";
import {
  selectTopic,
  selectSeeAllTopics,
  selectSubTopic,
  selectResetTopic,
} from "./Util/Analytics/TopicAnalytics";
import { Routes, Route } from "react-router-dom";

import { GetQuestions, GetTopics, GetLocations } from "./Util/Api";
import ScrollToTop from "./Util/ScrollToTop";
const App = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [questionId, setQuestionId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [question, setQuestion] = useState(null);
  const [year, setYear] = useState(null);
  const [vizType, setVizType] = useState("null");
  const [displayLocations, setDisplayLocations] = useState(false);

  const defaultLocation = {
    LocationAbbr: "ALL",
    LocationDesc: "All Locations",
    LocationType: "ALL",
  };
  const [location, setLocation] = useState(defaultLocation);
  const [locations, setLocations] = useState([]);
  const [subQuestionValue, setSubQuestionValue] = useState(
    question && question.SubQuestions && question.SubQuestions.length > 0
      ? question.SubQuestions[0].SubQuestionId
      : null
  );

  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const questionCodeParam = searchParams.get("questionId");
  const subQuestionCodeParam = searchParams.get("subQuestionId");
  const yearCodeParam = searchParams.get("year");
  const locationParam = searchParams.get("LocationAbbr");

  const [topics, setTopics] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    analytics.pageLoaded(); //set levels

    pageInit();
  }, []);

  async function pageInit() {
    await setDataLoading(true);
    let questionsData = [];
    let topicsData = [];
    let locationsData = [];
    await Promise.all([
      (async () => {
        topicsData = await fetchTopics();
        questionsData = await fetchQuestions();
        locationsData = await fetchLocations();
        // locationsData = questionsData.map((i) => i.LocationDesc);
      })(),
    ]);

    await setDataLoading(false);

    if (!questionsData || !locationsData || !questionsData) {
      setErrorLoadingData(true);
      setTopics([]);
      setLocations([]);
      setQuestions([]);
    } else {
      setErrorLoadingData(false);
      setTopics(topicsData);
      setLocations(locationsData);
      setQuestions(questionsData);

      //if we have a question code query string param
      if (questionCodeParam) {
        const question = questionsData.filter(
          (x) => x.QuestionId === questionCodeParam
        )[0];
        const location = locationsData.filter(
          (x) => x.LocationAbbr === locationParam
        )[0];

        //temp code. this will get removed when routing is corrected
        let path = window.location.hash.replace("#/", "");
        path = path.substring(0, path.indexOf("/"));

        await setVizType(path);
        await setQuestion(question);
        await setQuestionId(questionCodeParam);
        await setSubQuestionValue(subQuestionCodeParam);
        await setYear(yearCodeParam);
        if (location) setLocation(location);

        const nextSelectedData = {
          vizType: path,
          question: question,
          questionId: question.QuestionId,
          subQuestionValue: subQuestionCodeParam,
          year: question.LatestYear,
          location: location,
          scrollToTableHeader: false,
        };
        setSelectedData(nextSelectedData);
      }
    }
  }
  async function fetchLocations() {
    try {
      const locationResponse = await GetLocations();
      return locationResponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchQuestions() {
    try {
      const questionResponse = await GetQuestions();
      return questionResponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTopics() {
    try {
      const topicResponse = await GetTopics();
      return topicResponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  const displayLocationDropdown = () => {
    setDisplayLocations(!displayLocations);
  };

  async function handleHeaderhomeClick() {
    analytics.selectTitleHeader();

    await setLocation(defaultLocation);
    await setVizType(null);
    await setQuestion(null);
    await setQuestionId(null);
    await setSubTopicId(null);
    await setTopicId(null);
    await setSubQuestionValue(null);
    await setYear(null);
    navigate(``);
    //window.location.href = ENV.ProfilesBaseURL;
  }
  function handleTopicChange(topicId) {
    if (topicId) {
      selectTopic({ topicId: topicId });
    }

    setSubTopicId(null);
    setTopicId(topicId);
    navigate(topicId && topicId.length > 0 ? `?topicId=${topicId}` : "");
    //TODO: ADOBE-ANALYTICS: topicid change
  }

  const [subTopicId, setSubTopicId] = useState(null);
  function handleSubTopicChange(subTopicId) {
    if (subTopicId) {
      selectSubTopic({ topicId: topicId, subTopicId: subTopicId });
    }

    setSubTopicId(subTopicId);
    navigate(
      `?topicId=${topicId}${
        subTopicId && subTopicId.length > 0 ? "&subTopicId=" + subTopicId : ""
      }`
    );
    //TODO: ADOBE-ANALYTICS: subtopicid change
  }
  function handleYearChange(year) {
    setYear(year.Year);

    const nextSelectedData = {
      vizType: vizType,
      question: question,
      questionId: questionId,
      subQuestionValue: subQuestionValue,
      year: year.Year,
      location: location,
      scrollToTableHeader: false,
    };
    setSelectedData(nextSelectedData);
  }
  async function handleLocationChange(location, viz) {
    await setLocation(location);
    await setSubQuestionValue(null);
    setVizType(viz);
    const nextVizType = viz || vizType;
    const nextYear = year || question.LatestYear;
    const nextSelectedData = {
      vizType: nextVizType,
      question: question,
      questionId: questionId,
      subQuestionValue: null,
      subQuestion: null,
      year: nextYear,
      location: location,
      scrollToTableHeader: false,
    };
    setSelectedData(nextSelectedData);

    const route = `${nextVizType}/?questionId=${questionId}&LocationAbbr=${location.LocationAbbr}&year=${year}`;
    navigate(route);
  }

  //business logic for handleVizTypeChange
  //1. null passed in via one of the 4 objects below should clear the  value (most likely set to null)
  //2. if a value
  async function handleVizTypeChange({
    vizType,
    questionId,
    subQuestionId,
    locationAbbr,
  }) {
    if (questionId) {
      const question = questions.filter((x) => x.QuestionId === questionId)[0];

      //NEED TO FIX 'RESET' Logic
      const nextSubQuestionValue =
        subQuestionId === null || subQuestionId
          ? subQuestionId
          : subQuestionValue;

      const nextYear = year || question.LatestYear;

      const nextLocationAbbr = locationAbbr || location.LocationAbbr;
      const nextLocation = locations.filter(
        (x) => x.LocationAbbr === nextLocationAbbr
      )[0];

      const subQuestion = nextSubQuestionValue
        ? question.SubQuestions.filter(
            (x) => x.SubQuestionId === nextSubQuestionValue
          )[0]
        : null;
      const nextSelectedData = {
        vizType: vizType,
        question: question,
        questionId: questionId,
        subQuestionValue: nextSubQuestionValue,
        subQuestion: subQuestion,
        year: nextYear,
        location: nextLocation,
        scrollToTableHeader: nextSubQuestionValue ? true : false,
      };

      await setLocation(nextLocation);
      await setVizType(vizType);
      await setQuestion(question);
      await setQuestionId(questionId);
      await setSubQuestionValue(nextSubQuestionValue);
      await setYear(nextYear);

      await setSelectedData(nextSelectedData);

      if (nextSubQuestionValue) {
        const nextUrl = `${vizType}/?questionId=${
          question.QuestionId
        }&LocationAbbr=${
          locationAbbr || location.LocationAbbr
        }&subQuestionId=${subQuestionId}&year=${nextYear}`;
        navigate(nextUrl);
      } else {
        const nextnavigate = `${vizType}/?questionId=${questionId}${
          nextSubQuestionValue ? "&subQuestionId=" + nextSubQuestionValue : ""
        }&LocationAbbr=${location.LocationAbbr}&year=${nextYear}`;
        navigate(nextnavigate);
      }
    } else {
      await setLocation(defaultLocation);
      await setVizType(null);
      await setQuestion(null);
      await setQuestionId(null);
      await setSubTopicId(null);
      await setTopicId(null);
      await setSubQuestionValue(null);
      await setYear(null);
      navigate(``);
    }
  }
  
  //make sure both have loaded as both are needed
  if (dataLoading) return "loading";
  return (
    <div className="App" id="App" style={{ position: "relative", zIndex: 0 }}>
      <CDCHeader search={false}></CDCHeader>
      <div id="nav-header">
        <div role="navigation" className="container" id="top-nav-row">
          <nav className="navbar flex-md-nowrap p-0  " style={{}}>
            <button
              className="btn btn-link"
              style={{}}
              onClick={handleHeaderhomeClick}
            >
              School Health Profiles Explorer
            </button>
            <ul className="navbar-nav px-3">
              <li className="nav-item text-nowrap help-link">
               <a href={ENV.CDCBaseURL + "healthyyouth/data/profiles/profiles-explorer-help.html"} target="_blank" rel="noreferrer" >
                    <span className="cdc-icon-question-circle-solid"></span> Help
                    Using School Health Profiles Explorer
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div
          style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
          id="bottom-nav-row"
        >
          <nav
            role="navigation"
            aria-label="Breadcrumb"
            className="breadcrumbs pl-3 container"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL}>CDC</a>
              </li>
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL + "healthyyouth/index.htm"}>
                  DASH Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL + "healthyyouth/data/index.htm"}>
                  Data & Statistics Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a
                  href={ENV.CDCBaseURL + "healthyyouth/data/profiles/index.htm"}
                >
                  School Health Profiles
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <CDCBody>
        {errorLoadingData && (
          <div className="alert alert-danger" role="alert">
            Error loading data. Please refresh page.
          </div>
        )}
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                onVizTypeChange={handleVizTypeChange}
                onTopicChange={handleTopicChange}
                topicId={topicId}
                onSubTopicChange={handleSubTopicChange}
                subTopicId={subTopicId}
                questionId={questionCodeParam}
                topics={topics}
                questions={questions}
              ></Dashboard>
            }
          />
          <Route
            path={"/" + vizType}
            element={
              selectedData && selectedData.question ? (
                <DataVisualization
                  selectedData={selectedData}
                  onVizTypeChange={handleVizTypeChange}
                  setVizType={setVizType}
                  displayLocationDropdown={displayLocationDropdown}
                  displayLocations={displayLocations}
                  onLocationChange={handleLocationChange}
                  subQuestionValue={subQuestionValue}
                  onYearChange={handleYearChange}
                  locations={locations}
                ></DataVisualization>
              ) : (
                ""
              )
            }
          />
        </Routes>

        <ScrollToTop />
      </CDCBody>
      <CDCFooter></CDCFooter>
    </div>
  );
};

export default App;
