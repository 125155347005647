export const topicDictionary = {
  T01: {
    topicText: "Health Education", //<i class="fas fa-ambulance"></i>
    color: "#066194",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#066194" },
      { textColor: "#00000", fillColor: "#5C86AE" },
      { textColor: "#00000", fillColor: "#94ACC9" },
      { textColor: "#00000", fillColor: "#C9D5E4" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u19",
  },
  T02: {
    topicText: "Health Information to Increase Parents and Family Knowledge", // <i class="fas fa-smoking"></i>
    color: "#3c4c60",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#3C4C60" },
      { textColor: "#00000", fillColor: "#687f93" },
      { textColor: "#00000", fillColor: "#99b7c8" },
      { textColor: "#00000", fillColor: "#cdf1ff" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u21",
  },
  T03: {
    topicText: "Professional Development and Preparation", // <i class="fas fa-weight"></i>
    color: "#444444",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#444444" },
      { textColor: "#FFFFFF", fillColor: "#6e7175" },
      { textColor: "#00000", fillColor: "#9aa2ab" },
      { textColor: "#00000", fillColor: "#c9d5e4" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u20",
  },
  T04: {
    topicText: "Physical Education and Physical Activity", // <i class="fas fa-running"></i>
    color: "#9E3A3A",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#9E3A3A" },
      { textColor: "#00000", fillColor: "#BF645F" },
      { textColor: "#00000", fillColor: "#E08E87" },
      { textColor: "#00000", fillColor: "#FFB9B2" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u16",
  },
  T05: {
    topicText: "Nutrition Environment and Services", // <i class="fas fa-pills"></i>
    color: "#286665",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#286665" },
      { textColor: "#00000", fillColor: "#548E8D" },
      { textColor: "#00000", fillColor: "#7FB8B7" },
      { textColor: "#00000", fillColor: "#ACE4E3" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u0",
  },
  T06: {
    topicText: "Healthy and Safe School Environment", // <i class="fas fa-smile-wink"></i> (though there has also been discussions about <i class="fas fa-heart"></i>)
    color: "#4D4A83",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#4D4A83" },
      { textColor: "#00000", fillColor: "#7b76a9" },
      { textColor: "#00000", fillColor: "#aaa5d1" },
      { textColor: "#00000", fillColor: "#dbd6fa" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u2",
  },
  T07: {
    topicText: "Health Services", // <i class="fas fa-utensils"></i>
    color: "#C0532C",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#C0532C" },
      { textColor: "#00000", fillColor: "#d87d5c" },
      { textColor: "#00000", fillColor: "#eca78d" },
      { textColor: "#00000", fillColor: "#fdd1c1" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u1",
  },
  T08: {
    topicText: "Family Engagement", // <i class="fas fa-medkit"></i>
    color: "#6C2865",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#6C2865" },
      { textColor: "#FFFFFF", fillColor: "#985f90" },
      { textColor: "#00000", fillColor: "#c596bd" },
      { textColor: "#00000", fillColor: "#f2d0ec" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u6",
  },
  T09: {
    topicText: "Youth Development Programs", // <i class="fas fa-medkit"></i>
    color: "#008292",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#008292" },
      { textColor: "#00000", fillColor: "#4ba9b5" },
      { textColor: "#00000", fillColor: "#7bd1d9" },
      { textColor: "#00000", fillColor: "#a9fbff" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u4",
  },
  T10: {
    topicText: "School Health Coordination", // <i class="fas fa-medkit"></i>
    color: "#80783A",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#80783A" },
      { textColor: "#00000", fillColor: "#a79e63" },
      { textColor: "#00000", fillColor: "#d0c68f" },
      { textColor: "#00000", fillColor: "#f9efbc" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u3",
  },
  T11: {
    topicText: "Before And After School Programs", // <i class="fas fa-medkit"></i>
    color: "#714E26",
    colors: [
      { textColor: "#FFFFFF", fillColor: "#714E26" },
      { textColor: "#00000", fillColor: "#9f7e55" },
      { textColor: "#00000", fillColor: "#ceb188" },
      { textColor: "#00000", fillColor: "#ffe7bf" },
      { textColor: "#00000", fillColor: "#F2F2F2" },
    ],
    icon: "fs1 mx-1 icon-u5",
  },
};

export const topicList = [
  "T01",
  "T02",
  "T03",
  "T04",
  "T05",
  "T06",
  "T07",
  "T08",
  "T09",
  "T10",
  "T11",
];
