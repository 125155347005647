import React from "react";
import { BsChevronDown } from "react-icons/bs";
import LocationDropdownOptions from "./LocationDropdownOptions";

const LocationDropdown = ({
  displayLocations,
  displayLocationDropdown,
  location,
  locations,
  onLocationChange,
  setLocationSelectedFromDropdownMenu,
}) => {
  return (
    <div className="col-12" style={{ "padding-top": 7 }}>
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-4 col-lg-4">Location: </div>
        <div
          tabIndex="0"
          className="col-sm-12 col-md-8 col-lg-8 selectpicker form-control "
          onClick={(e) => displayLocationDropdown(e)}
          onKeyDown={(e) => e.code === "Enter" && displayLocationDropdown(e)}
          style={{
            position: "relative",
            zIndex: 9,
            margin: "0",
          }}
          role="combobox"
          aria-label="select-location"
          aria-expanded="false"
          aria-controls="location-dropdown-menu"
        >
          {location ? location.LocationDesc : "All Locations"}
          <span
            style={{
              float: "right",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <BsChevronDown />
          </span>
        </div>
      </div>
      <div className="row justify-content-end">
        <div
          className="col-md-12 col-lg-8 location-dropdown"
          id="location-dropdown-menu"
          style={!displayLocations ? { display: "none" } : { display: "flex" }}
        >
          {locations && (
            <LocationDropdownOptions
              displayLocations={displayLocations}
              displayLocationDropdown={displayLocationDropdown}
              locations={locations}
              onLocationChange={onLocationChange}
              setLocationSelectedFromDropdownMenu={
                setLocationSelectedFromDropdownMenu
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationDropdown;
