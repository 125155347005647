import { topicDictionary } from "../Util/tileDictionary";
import * as analytics from "../Util/Analytics/QuestionAnalytics";
const SubQuestion = ({
  subQuestion,
  handleSubQuestionChange,
  isSelected,
  question,
}) => {
  function handleCardClick() {
    analytics.selectSubQuestion({
      questionId: subQuestion.QuestionId,
      subQuestionId: subQuestion.SubQuestionId,
    });
    handleSubQuestionChange(subQuestion);
  }

  return (
    <div key={subQuestion.SubQuestionId} className="subquestion-container">
      <button
        value={subQuestion.SubQuestionId}
        className="section-card btn btn-primary card-not-selected my-1 col-12"
        is-selected={isSelected.toString()}
        onClick={handleCardClick}
        style={
          isSelected
            ? {
                backgroundColor: topicDictionary[question.TopicId].color,
                color: "white",
              }
            : {}
        }
      >
        {subQuestion.SubQuestion}
      </button>
    </div>
  );
};

export default SubQuestion;
