import { topicDictionary } from "../Util/tileDictionary";
import LocationDropdown from "./LocationDropdown";
import YearDropdown from "./YearDropdown";
import DataVisualizationToggle from "./DataVisualizationToggle";
import ShareLink from "./ShareLink";
import SubQuestions from "./SubQuestions";
import * as analytics from "../Util/Analytics/QuestionAnalytics";

const QuestionHeader = ({
  displayLocationDropdown,
  displayLocations,
  handleSubQuestionChange,
  isPatternsOverTime,
  locations,
  onVizTypeChange,
  onLocationChange,
  onYearChange,
  onReturnToLocationList,
  onReturnToLocationSubQuestionList,
  selectedData,
  setLocationSelectedFromDropdownMenu,
  setIsPatternsOverTime,
  showReturnToLocationView,
  showReturnToSubQuestionView,
  shortNavFootNoteSymbol,
  subQuestion,
}) => {
  const question = selectedData ? selectedData.question : null;
  const year = selectedData ? selectedData.year : null;
  const subQuestionValue = selectedData ? selectedData.subQuestionValue : null;
  const location = selectedData ? selectedData.location : null;
  const vizType = selectedData ? selectedData.vizType : null;
  const locationAbbr = selectedData ? selectedData.location.LocationAbbr : null;
  const locationDesc = selectedData ? selectedData.location.LocationDesc : null;
  const icon =
    question && question.TopicId ? topicDictionary[question.TopicId].icon : "";

  function handleSelectAnotherQuestion() {
    analytics.selectAnotherQuestion();
    onVizTypeChange({ questionId: null });
  }

  const questionId =
    question && question.QuestionId ? question.QuestionId : null;
  const subQuestionId =
    subQuestion && subQuestion.SubQuestionId ? subQuestion.SubQuestionId : null;

  return (
    <div>
      <div className="row question-header">
        <div className="col-12 p-2 question-header__select-another-question">
          <div className="row p-0">
            <div className="col-12 p-0">
              <button
                className="btn btn-link"
                onClick={handleSelectAnotherQuestion}
              >
                Select another question
              </button>
            </div>
          </div>
        </div>

        <div
          aria-label="Question"
          className={
            "question-header__topic-subtopic-header yrbs-cat__color--" +
            question.TopicId
          }
          style={{
            backgroundColor: topicDictionary[question.TopicId].color,
          }}
        >
          <div tabIndex="0" role="link">
            <div className="row tile-header">
              <div className="col-2" aria-hidden="true"></div>
              <div className="col-12 p-2">
                <span className={"topic-icon pr-2 " + icon}></span>
                <span className="topic-description">
                  {question.TopicDescription}
                </span>
                -
                <span className="subtopic-description">
                  {question.SubTopicDescription}
                </span>
              </div>
            </div>

            <h1 className="question-description p-2">
              {question.LongQuestionDescription}&nbsp;{shortNavFootNoteSymbol}
              <br />
              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {question.SurveyText}, {year}
              </span>
            </h1>
          </div>
          <div className="d-flex question-header__location-section justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-5 p-2 d-flex justify-content-center">
              <LocationDropdown
                onLocationChange={onLocationChange}
                locationAbbr={locationAbbr}
                location={location}
                setLocationSelectedFromDropdownMenu={
                  setLocationSelectedFromDropdownMenu
                }
                displayLocations={displayLocations}
                displayLocationDropdown={displayLocationDropdown}
                locations={locations}
              ></LocationDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-0 mt-4">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-0">
          {showReturnToLocationView && (
            <button
              className="btn btn-link"
              style={{ fontSize: "17px" }}
              onClick={onReturnToLocationList}
            >
              <span
                style={{ fontSize: "25px" }}
                className="cdc-icon-arrow-alt-circle-left-solid mr-2"
              ></span>
              Return to All Locations Survey Results
            </button>
          )}
          {showReturnToSubQuestionView && (
            <button
              className="btn btn-link"
              style={{ fontSize: "17px" }}
              onClick={() => {
                onReturnToLocationSubQuestionList();
                setIsPatternsOverTime(false);
                setLocationSelectedFromDropdownMenu(false);
              }}
            >
              <span
                style={{ fontSize: "25px" }}
                className="cdc-icon-arrow-alt-circle-left-solid mr-2"
              ></span>
              Return to {location.LocationDesc}{" "}
              {location.LocationType === "National" ? `` : `Survey`} Results
            </button>
          )}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-0">
          {locationAbbr === "ALL" && (
            <DataVisualizationToggle
              onVizTypeChange={onVizTypeChange}
              vizType={vizType}
              question={question}
              subQuestionValue={subQuestionValue}
            />
          )}
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-0 text-right">
          <ShareLink />
        </div>
      </div>

      <hr />
      <div className="row">
        {isPatternsOverTime ? (
          ""
        ) : (
          <h2 className="col-12 question-header__location-text">
            {selectedData.location.LocationDesc === "Nationwide"
              ? `${locationDesc} Results`
              : `${locationDesc} Survey Results`}
          </h2>
        )}
        {isPatternsOverTime && subQuestion && (
          <h2
            className="patterns-over-time col-12 question-header__location-text"
            style={{ color: "#555" }}
          >
            {subQuestion.SubQuestion}
          </h2>
        )}
      </div>
      {!isPatternsOverTime && (
        <div className="row">
          <div className="col-12">
            <YearDropdown
              onYearChange={onYearChange}
              year={year}
              questionId={questionId}
              subQuestionId={subQuestionId}
              locationAbbr={locationAbbr}
            ></YearDropdown>
          </div>
        </div>
      )}
      <div className="row mb-2">
        <div className="col-12">
          {locationAbbr === "ALL" && (
            <SubQuestions
              subQuestions={question.SubQuestions}
              handleSubQuestionChange={handleSubQuestionChange}
              subQuestionId={
                subQuestion ? subQuestion.SubQuestionId : subQuestionValue
              }
              question={question}
              vizType={vizType}
            ></SubQuestions>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionHeader;
