import * as ENV from "../env";
export function doAnalytics({ levels, AzEntry, channel }) {
    if (!window.s) return;
    const _s = window.s,
        _siteCatalyst = window.siteCatalyst,
        _updateVariables = window.updateVariables;

    const pageHref = window.location.href.toLowerCase(),
        pageTitle = document.title,
        hostName = window.location.hostname.toLowerCase(),
        simpleUrl = pageHref.split('?')[0].split('#')[0];

    // s.pageName is not included in the documentation but is necessary for Analytics Reporting
    _s.pageName = pageTitle;
    // It was required for TP3 documentation, but got left out in future instruction

    _s.prop2 = pageHref;
    _s.prop26 = pageTitle;
    _s.prop30 = pageTitle;
    _s.prop31 = pageHref;
    _s.prop46 = pageHref;
    _s.server = hostName;
    //Simplified URL
    _s.prop73 = simpleUrl;
    _s.eVar73 = simpleUrl;

    _s.channel = channel;

    if (levels) {
        if (levels[0]) {
            //* Center Name *
            _siteCatalyst.setLevel1(levels[0]);
        }
        if (levels[1]) {
            //* Division, Office or Program Name *
            _siteCatalyst.setLevel2(levels[1]);
        }
        if (levels[2]) {
            //* CDC Topic Name *
            _siteCatalyst.setLevel3(levels[2]);
        }

        if (levels[3]) {
            //* Chronic web application *
            _siteCatalyst.setLevel4(levels[3]);
        }
        if (levels[4]) {
            //* DPH CDI *
            _siteCatalyst.setLevel5(levels[4]);
        }



    }

    if (AzEntry) {
        _siteCatalyst.setAzEntry(AzEntry);
    }

    // Update the level variables here.
    _updateVariables(_s);

    // Set the referrer value if passed on the querystring from a CDC page-level redirect.
    const qs = (function (a) {
        if (a === '') {
            return;
        }
        const b = {};
        for (let i = 0; i < a.length; ++i) {
            const p = a[i].split('=');
            if (p.length !== 2 || p[0] == '') {
                continue;
            }
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
        }
        return b;
    })(window.location.search.substr(1).split('&'));

    const refParam = qs['CDC_AA_refVal'];
    if (refParam) {
        _s.referrer = refParam;
    }

    const s_code = _s.t();
    if (s_code) document.write(s_code);
}

export function doInteraction(
    interaction,
    applicationTitle = 'School Health Profiles Explorer (SHPE)',
    trackVars = 'prop41,prop40,prop49,prop46,prop2,prop31,channel'
) {

    if (!window.s) return;

    if (ENV.EnableAnalyticInteractions) {
        const _s = window.s;
        _s.linkTrackVars = trackVars;
        _s.prop41 = applicationTitle; // This is used for creating analytics buckets in the report
        _s.prop40 = interaction;
        _s.tl(true, 'o', interaction);
    }

    if (ENV.EnableAnalyticConsoleLog) {
        console.log(interaction);
    }
}
