import React, { useEffect } from "react";

import { topicDictionary } from "../Util/tileDictionary";
const Topic = ({
  hasSelection,
  isSelected,
  onTopicChange,
  onSubTopicChange,
  topicId,
  topic,
}) => {
  useEffect(() => {}, [topicId, isSelected]);

  function handleSubTopicFilterChange(e) {
    const value = e.target.value;
    value === "ALL" ? onSubTopicChange(null) : onSubTopicChange(value);
    //TODO: ADOBE-ANALYTICS: Dashboard tile filter by SubTopicId
  }

  function handleTopicChange() {
    onTopicChange(topicId);
  }

  const icon = topicDictionary[topicId] ? topicDictionary[topicId].icon : "";
  return (
    <div
      className={
        hasSelection && !isSelected
          ? "topic-item-container yrbs-cat__color--NotSelected"
          : "topic-item-container yrbs-cat__color--" + topicId
      }
      style={{
        backgroundColor:
          hasSelection && !isSelected ? "" : topicDictionary[topicId].color,
      }}
    >
      <div
        tabIndex="0"
        className="nav-link-container row m-1"
        onClick={handleTopicChange}
        onKeyDown={(e) => {
          console.log("handleTopicChange", e.code);
          e.code === "Enter" && handleTopicChange();
        }}
        role="button"
      >
        <div className="col-2 p-0 ">
          <span className={"topic-icon " + icon}></span>
        </div>
        <div className="col-10 ">
          <span className="topic"> {topic.TopicDescription.toLowerCase()}</span>
        </div>
      </div>
      {isSelected && (
        <div className="sub-topic-selector-container">
          <label style={{ fontStyle: "italic", fontSize: "small" }}>
            Select a Subtopic
            <br />
            <div className="pr-2 pt-1">
              <select
                tabIndex="0"
                className="selectpicker form-control"
                name="healthEdSubtopic"
                defaultValue="all"
                onChange={handleSubTopicFilterChange}
              >
                <option value="ALL">View All</option>
                {topic &&
                  topic.SubTopics &&
                  topic.SubTopics.map((option, index) => {
                    return (
                      <option
                        tabIndex="0"
                        key={option.SubTopicId}
                        value={option.SubTopicId}
                      >
                        {option.SubTopicDescription}
                      </option>
                    );
                  })}
              </select>
            </div>
          </label>
        </div>
      )}
    </div>
  );
};

export default Topic;
