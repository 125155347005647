import { doInteraction, doAnalytics } from './Analytics';
import * as ENV from "../env";

export function pageLoaded() {
    if (ENV.EnableAnalyticInteractions) {
        doAnalytics({
            levels: ['Chronic', 'DASH', 'School Health Profiles', 'Chronic Web Application', 'DASH SHPE'],
            channel: 'School Health Profiles Explorer'
        });
    }
    if (ENV.EnableAnalyticConsoleLog) {
        console.log('Levels: Chronic TODO')
        
    }
}
export function selectTitleHeader() {
    doInteraction(`DASH SHPE|GLOBAL, TITLE`);
}
export function selectHelp() {
    doInteraction(`DASH SHPE|GLOBAL, HELP`);
}
export function selectScrollToTop() {
    doInteraction(`DASH SHPE|GLOBAL, HOME, SCROLL`);
}
