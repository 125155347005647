import { doInteraction } from './Analytics';



//topics
export function selectTopic({ topicId }) {
    doInteraction(`DASH SHPE|HOME, TOPIC, ${topicId}`);
}
export function selectSeeAllTopics() {
    doInteraction(`HOME, TOPIC, All`);
}
export function selectSubTopic({ topicId, subTopicId }) {
    doInteraction(`HOME, SUBTOPIC, ${topicId}, ${subTopicId}`);
}
export function selectResetTopic() {
    doInteraction(`HOME, TOPIC, Reset`);
}


