import LocationOption from "./LocationOption";
import "../scss/locationDropdown.scss";

const LocationDropdownOptions = ({
  displayLocations,
  displayLocationDropdown,
  location,
  locations,
  onLocationChange,
  setLocationSelectedFromDropdownMenu,
}) => {
  const locationTypes =
    locations && Array.from(new Set(locations.map((o) => o.LocationType)));

  const renderLocationOptions = (locations) => {
    const opts = locationTypes.map((lt, index) => (
      <LocationOption
        key={`location-${index}`}
        locations={locations}
        onLocationChange={onLocationChange}
        setLocationSelectedFromDropdownMenu={
          setLocationSelectedFromDropdownMenu
        }
        displayLocations={displayLocations}
        displayLocationDropdown={displayLocationDropdown}
        lt={lt}
        index={index}
        location={location}
      />
    ));
    return opts;
  };

  return (
    <div style={{ top: displayLocations ? "0" : "-184px" }}>
      {locations && renderLocationOptions(locations)}
    </div>
  );
};

export default LocationDropdownOptions;
