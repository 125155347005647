import { doInteraction } from './Analytics';




export function selectSubQuestion({ questionId, subQuestionId }) {
    doInteraction(`DASH SHPE|HOME, SUBQ, ${questionId}, ${subQuestionId}`);
}
export function selectTable() {
    doInteraction(`DASH SHPE|HOME, TABLE`);
}
export function selectMap() {
    doInteraction(`DASH SHPE|HOME, MAP`);
}



