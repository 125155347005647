import { doInteraction } from './Analytics';
//topics
export function selectAnotherQuestion() {
    doInteraction(`DASH SHPE|QVIEW, Return`);
}
export function selectLocation({ locationAbbr }) {
    doInteraction(`DASH SHPE|QVIEW, LOCATION, ${locationAbbr}`);
}
export function selectTable() {
    doInteraction(`DASH SHPE|QVIEW, VIEW, TABLE`);
}
export function selectMap() {
    doInteraction(`DASH SHPE|QVIEW, VIEW, MAP`);
}
export function selectShareLink() {
    doInteraction(`DASH SHPE|QVIEW, SHARE`);
}
export function selectSubQuestion({ questionId, subQuestionId }) {
    doInteraction(`DASH SHPE|QVIEW, SUBQ, ${questionId}, ${subQuestionId}`);
}
export function selectExport({ exportType }) {
    doInteraction(`DASH SHPE|QVIEW, EXPORT, ${exportType}`);
}


