import React, { useEffect } from "react";

const Dropdown = ({
  options,
  dropdownTitle,
  dropdownID,
  value,
  onChange,
  additionalOptions,
  itemID,
  itemName,
  showEmpty,
  emptyText,
}) => {
  useEffect(() => {}, [value]);
  useEffect(() => {
    const option = options && options.length ? options[0] : null;

    if (option) {
      // TODO: confirm functionality of onLocationChange() call
      // onLocationChange(option);
    }
  }, [options]);

  if (showEmpty === undefined) showEmpty = true;
  function handleOnChange(e) {
    const value =
      e.target.value !== null && e.target.value !== "" ? e.target.value : null;
    const index = e.target.selectedIndex;
    const text = e.target.options[index].text;

    onChange({ [itemName]: text, [itemID]: value });
  }

  const selectedValue = value && value != null ? value : "";

  return (
    <div className="row">
      <div className={`col-sm-6 col-md-2 col-lg-2 mt-1`}>
        <span>
          {dropdownTitle}
          {options.length === 1 && (
            <span>
              : &nbsp;&nbsp;&nbsp;<b>{options[0][itemID]}</b>
            </span>
          )}
        </span>
      </div>
      <div className="col-sm-6 col-md-2 col-lg-2 p-0">
        {options && options.length > 1 && (
          <select
            className="selectpicker form-control"
            id={dropdownID}
            onChange={handleOnChange}
            required={true}
            value={selectedValue}
            style={itemName === "Year" && { display: "flex" }}
          >
            {showEmpty && (
              <option key="DEFAULT" value="">
                {emptyText}
              </option>
            )}

            {additionalOptions &&
              additionalOptions.map((option, index) => {
                return option.IsOptGroup ? (
                  <optgroup key={option.Label} label={option.Label}></optgroup>
                ) : (
                  <option key={option[itemID]} value={option[itemID]}>
                    {option[itemName]}
                  </option>
                );
              })}

            {options &&
              options.map((option, index) => {
                return (
                  <option key={option[itemID]} value={option[itemID]}>
                    {option[itemName]}
                  </option>
                );
              })}
          </select>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
