import React, { useState } from "react";
import Topic from "./Topic";
import {
  selectSeeAllTopics,
  selectResetTopic,
} from "../Util/Analytics/TopicAnalytics";

const Topics = ({
  onSearchChange,
  onSubTopicChange,
  onTopicChange,
  topicId,
  topics,
}) => {
  let timer = null;
  const [searchTerm, setSearchTerm] = useState("");

  function setSearchTermTimeout() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      triggerSearch();
    }, 100);
  }
  function triggerSearch() {
    onSearchChange(searchTerm);
  }

  function handleTopicAndSearchReset() {
    selectResetTopic();

    onSearchChange("");

    clearTimeout(timer);
    timer = null;

    onTopicChange(null);
    setSearchTerm("");
  }

  function seeAllTopics() {
    selectSeeAllTopics();

    onSearchChange("");

    clearTimeout(timer);
    timer = null;

    onTopicChange(null);
    setSearchTerm("");
  }

  return (
    <>
      <div className="row mt-2">
        <div
          className="col-sm-12 col-md-12 col-lg-8 text-center mt-2"
          style={{ fontSize: "medium" }}
        >
          Search Questions
        </div>
        <div className="col-sm-12 col-md-12 col-lg-3 text-center mt-0">
          <button
            type="button"
            className="btn btn-link underline"
            onClick={handleTopicAndSearchReset}
          >
            Reset
          </button>
        </div>
      </div>

      <div className="row mt-0 mb-2 px-3">
        <input
          type="text"
          value={searchTerm}
          name="searchQuestion"
          className="form-control"
          style={{ fontSize: "small" }}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyUp={setSearchTermTimeout}
          aria-label="search"
        />
      </div>
      <div className="row mt-2 mb-2">
        <div
          className="col-12"
          style={{ fontSize: "medium", paddingTop: "10px" }}
        >
          Select Topic
        </div>
      </div>

      <div aria-label="Filters">
        {topics &&
          topics.length > 0 &&
          topics.map((topic, i) => (
            <Topic
              key={`topic-${i}`}
              topicId={topic.TopicId}
              topic={topic}
              isSelected={topicId === topic.TopicId ? true : false}
              hasSelection={topicId != null ? true : false}
              onTopicChange={onTopicChange}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              onSubTopicChange={onSubTopicChange}
            />
          ))}
      </div>

      <br />
      <div
        role="link"
        tabIndex="0"
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
        className="nav-link"
        onClick={seeAllTopics}
      >
        See All Topics
      </div>
    </>
  );
};

export default Topics;
