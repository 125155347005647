import React from "react";
import * as analytics from "../../Util/Analytics/TableAnalytics";

const SubQuestionTableRow = ({
  allLocations,
  comparisonData,
  isPatternsOverTime,
  linkFirstColumn,
  onSubQuestionChange,
  onLocationChange,
  printRange,
  subQuestionData,
}) => {
  const rowText = GetSubRowText();
  function GetSubRowText() {
    if (isPatternsOverTime) {
      return subQuestionData.Yr;
    }
    if (allLocations) {
      return subQuestionData.LocationTxt;
    }
    if (subQuestionData && subQuestionData.SubQuestion !== "") {
      return subQuestionData.SubQuestion;
    }
    return "";
  }

  return (
    <>
      {printRange && subQuestionData.length > 0 ? (
        <tr>
          <td style={{ textAlign: "left", borderRight: "none" }}>
            <b>Range</b>
          </td>
          <td colSpan="1" style={{}}>
            <strong>{subQuestionData[0].Range}</strong>
          </td>
          <td style={{}}></td>
          <td style={{}}></td>
        </tr>
      ) : !printRange ? (
        <>
          <tr
            id={
              subQuestionData.LocationAlias &&
              subQuestionData.LocationAlias === "National"
                ? `US`
                : ""
            }
          >
            <th class="th-secondary" style={{ textAlign: "left" }}>
              {linkFirstColumn && !isPatternsOverTime ? (
                <button
                  className="btn btn-link m-0 p-0 text-left"
                  onClick={(e) => {
                    e.preventDefault();
                    if (allLocations) {
                      analytics.selectionTableLocation({
                        locationAbbr: subQuestionData.LocationAbbr,
                      });
                      onLocationChange(
                        {
                          LocationAbbr: subQuestionData.LocationAbbr,
                          LocationDesc: subQuestionData.LocationTxt,
                          LocationType: subQuestionData.LocationType,
                        },
                        "table"
                      );
                    } else {
                      analytics.selectTableSubQuestion({
                        questionId: subQuestionData.QuestionId,
                        subQuestionId: subQuestionData.SubQuestionId,
                      });
                      onSubQuestionChange(subQuestionData.SubQuestionId);
                    }
                  }}
                >
                  {rowText}
                </button>
              ) : (
                <>{rowText}</>
              )}
            </th>
            <td>{subQuestionData && subQuestionData.NPercent}</td>
            <td>{subQuestionData && subQuestionData.ConfidenceInterval}</td>
            <td>{subQuestionData && subQuestionData.SampleSize}</td>
            {comparisonData && (
              <>
                <td>{comparisonData[0].NPercent}</td>
                <td>{comparisonData[0].ConfidenceInterval}</td>
                <td>{comparisonData[0].NPercent}</td>
              </>
            )}
          </tr>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubQuestionTableRow;
