import { useState, useEffect } from "react";
import * as analytics from "../Util/Analytics/QuestionAnalytics";
const LocationOption = ({
  displayLocations,
  displayLocationDropdown,
  index,
  locations,
  lt,
  onLocationChange,
  setLocationSelectedFromDropdownMenu,
}) => {
  useEffect(() => {
    toggleLocationOptions(true);
  }, [displayLocations]);

  const ltOptions = locations.filter((i) => i.LocationType === lt);
  const updateLocation = (i) => {
    analytics.selectLocation({ locationAbbr: ltOptions[i].LocationAbbr });
    onLocationChange(ltOptions[i], "table");
  };

  const [displayLocationOptions, setDisplayLocationOptions] = useState(false);
  const toggleLocationOptions = (displayLocationOptions) => {
    setDisplayLocationOptions(!displayLocationOptions);
  };

  const locationCategory = () => {
    switch (lt) {
      case "All":
        return "All Locations";
      case "National":
        return locations.filter((i) => i.LocationType === lt)[0].LocationDesc;
      case "State":
        return "States";
      case "Local":
        return "Local School Districts";
      default:
        return lt;
    }
  };

  function handleKeyDown(e) {
    if (e.code === "Enter") {
      if (lt === "All" || lt === "National") {
        updateLocation(0);
        displayLocationDropdown();
      } else toggleLocationOptions(displayLocationOptions);
    }
  }

  return (
    <>
      <div
        tabIndex="0"
        onClick={
          lt === "All" || lt === "National"
            ? (e) => {
                e.preventDefault();
                updateLocation(0);
                displayLocationDropdown();
              }
            : () => toggleLocationOptions(displayLocationOptions)
        }
        onKeyDown={(e) => handleKeyDown(e)}
        style={{ cursor: "pointer", position: "relative", zIndex: 9 }}
        className={`main-selection ${
          (lt === "All" || lt === "National") && "location-option"
        }`}
      >
        {ltOptions.length > 1 && lt !== "National" && (
          <b onClick={() => toggleLocationOptions(displayLocationOptions)}>
            {displayLocationOptions ? `-` : `+`}
          </b>
        )}
        {`${locationCategory()}`}
      </div>
      {ltOptions.length > 1 && (
        <div
          className="collapsible-list"
          style={{
            height: displayLocationOptions && "200px",
            overflow: "scroll",
          }}
        >
          <ul
            key={`location-${index}`}
            style={
              displayLocationOptions
                ? { top: 0, display: "block" }
                : { top: "auto", display: "none" }
            }
          >
            {lt !== "National" &&
              lt !== "All" &&
              ltOptions.map((o, i) => {
                return (
                  <li
                    key={`option-${i}`}
                    tabindex="0"
                    className="location-option"
                    onClick={() => {
                      updateLocation(i);
                      displayLocationDropdown();
                      setLocationSelectedFromDropdownMenu(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        updateLocation(i);
                        displayLocationDropdown();
                        setLocationSelectedFromDropdownMenu(true);
                      }
                    }}
                  >
                    {o.LocationDesc}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};

export default LocationOption;
