import React, { useEffect, useState, useRef } from "react";
import MapView from "./Map";
import Table from "./Table";
import QuestionHeader from "./QuestionHeader";
import { GetQuestionDataAllLocations } from "../Util/Api";
import PrintPdf from "./TableButtons/PrintPdf";
import ExportCsv from "./TableButtons/ExportCsv";
import {
  GetQuestionOneLocation,
  GetSubQuestionAllLocations,
  GetPatternsOverTime,
} from "../Util/Api";
import { useNavigate } from "react-router-dom";
import * as analytics from "../Util/Analytics/TableAnalytics";
const DataVisualization = ({
  displayLocations,
  displayLocationDropdown,
  locations,
  onVizTypeChange,
  onYearChange,
  onLocationChange,
  selectedData,
}) => {
  const question = selectedData ? selectedData.question : null;
  const year = selectedData ? selectedData.year : null;
  const subQuestionValue = selectedData ? selectedData.subQuestionValue : null;
  const location = selectedData ? selectedData.location : null;
  const vizType = selectedData ? selectedData.vizType : null;
  const subQuestion = selectedData.subQuestion
    ? selectedData.subQuestion
    : selectedData.subQuestionValue
      ? selectedData.question.SubQuestions.find(
          (i) => i.SubQuestionId === selectedData.subQuestionValue
        )
      : null;
  const scrollToTableHeader = selectedData
    ? selectedData.scrollToTableHeader
    : null;

  let navigate = useNavigate();
  const [subQuestionCSVData, setSubQuestionCSVData] = useState(null);
  const [subQuestionTableData, setSubQuestionTableData] = useState(null);
  const [comparisonData, setComparisonData] = useState(false);
  const [footnotes, setFootnotes] = useState(false);
  const [isPatternsOverTime, setIsPatternsOverTime] = useState(false);
  const tableRef = useRef(null);
  const [shortNavFootNoteSymbol, setShortNavFootNoteSymbol] = useState("");
  const [
    locationSelectedFromDropdownMenu,
    setLocationSelectedFromDropdownMenu,
  ] = useState(false);

  useEffect(() => {
    if (question.SubQuestions.length === 0 && location.LocationAbbr === "ALL") {
      setIsPatternsOverTime(false);
      getData(question.QuestionId, question.LatestYear);
    } else if (
      question.SubQuestions.length === 0 &&
      location.LocationAbbr !== "ALL"
    ) {
      setIsPatternsOverTime(true);
      fetchPatternsOverTime();
    } else if (
      question.SubQuestions.length === 0 &&
      location.LocationAbbr !== "ALL" &&
      selectedData.subQuestionValue !== null
    ) {
      setIsPatternsOverTime(true);
      fetchPatternsOverTime();
    } else if (
      question.SubQuestions.length > 0 &&
      location.LocationAbbr !== "ALL" &&
      subQuestionValue
    ) {
      setIsPatternsOverTime(true);
      fetchPatternsOverTime();
    } else if (subQuestionValue || location.LocationAbbr) {
      setIsPatternsOverTime(false);
      fetchTableData(
        subQuestionValue,
        questionId,
        year || question.LatestYear,
        location.LocationAbbr
      );
    }
  }, [location, year, subQuestionValue]);

  async function getData(id, yr) {
    await setSubQuestionTableData(null);
    const response = await GetQuestionDataAllLocations({
      questionId: id,
      year: yr,
    });
    const questionData =
      response &&
      (await {
        tableData: response.data.tableData,
        medianRangeData: response.data.medianRangeData,
        legendData: response.data.mapData[0].KeyItems,
      }); //note data structure change
    setSubQuestionTableData(questionData);
    setSubQuestionCSVData(questionData);
    setFootnotes(await response.data.footnoteData);
    setShortNavFootNoteSymbol(await response.data.shortNavFootnoteSymbol);
  }

  function handleSubQuestionChange(obj) {
    onVizTypeChange({
      vizType: vizType,
      questionId: question.QuestionId,
      subQuestionId: obj.SubQuestionId,
      locationAbbr: location.LocationAbbr,
    });
  }

  async function fetchPatternsOverTime() {
    await setSubQuestionTableData(null);
    const response = await GetPatternsOverTime({
      locationAbbr: location.LocationAbbr,
      questionId: question.QuestionId,
      subQuestionId: subQuestionValue ? subQuestionValue : "",
    });
    const questionData = response.data;
    setSubQuestionTableData(questionData);
    setSubQuestionCSVData(questionData);
    setFootnotes(await response.data.footnoteData);
  }

  async function handlePatternsOverTime(SubQuestionId) {
    onVizTypeChange({
      vizType: vizType,
      questionId: question.QuestionId,
      subQuestionId: SubQuestionId,
      locationAbbr: location.LocationAbbr,
    });
  }
  async function handleReturnToLocationList() {
    onVizTypeChange({
      vizType: vizType,
      questionId: question.QuestionId,
      subQuestionId: subQuestion ? subQuestion.SubQuestionId : null,
      locationAbbr: "ALL",
    });
    setIsPatternsOverTime(false);
  }

  async function handleReturnToLocationSubQuestionList() {
    analytics.selectTableReturn();
    onVizTypeChange({
      vizType: vizType,
      questionId: question.QuestionId,
      subQuestionId: null,
      locationAbbr: location.LocationAbbr,
    });
    setIsPatternsOverTime(false);
  }

  async function fetchTableData(subQuestionId, questionId, year, locationAbbr) {
    if (locationAbbr === "ALL") {
      await fetchTableDataForAllLocation(subQuestionId, questionId, year);
    } else {
      await fetchTableDataForLocation(locationAbbr, questionId, year);
    }
  }

  async function fetchTableDataForLocation(locationAbbr, questionId, year) {
    await setSubQuestionTableData(null);
    const response = await GetQuestionOneLocation({
      locationAbbr,
      questionId,
      year,
    });

    //Modified to get table data from 'tableData' object else 'data'.
    //This is a modification to allow for getting footnote data in followup release
    let questionData =
      response.data.tableData && response.data.tableData.length
        ? response.data.tableData
        : response.data;

    if (questionData && questionData.length > 0) {
      //Set the table data
      setSubQuestionTableData(questionData);
      setSubQuestionCSVData(questionData);
      setFootnotes(await response.data.footnoteData);
      setShortNavFootNoteSymbol(await response.data.shortNavFootnoteSymbol);

      //Set the route. Let's get the parameters from the data
      const QuestionId = questionData[0].QuestionId;
      const LocationAbbr = questionData[0].LocationAbbr;
      const Yr = questionData[0].Yr;
      navigate(
        `?questionId=${QuestionId}&LocationAbbr=${LocationAbbr}&year=${Yr}`
      );
    } else {
      //Clear the table data
      setSubQuestionTableData(null);
      setSubQuestionCSVData(null);

      //we don't have data. just set what was sent to the api
      navigate(
        `?questionId=${questionId}&LocationAbbr=${locationAbbr}&year=${year}`
      );
    }
  }

  async function fetchTableDataForAllLocation(subQuestionId, questionId, year) {
    await setSubQuestionTableData(null);
    const response = await GetSubQuestionAllLocations({
      subQuestionId,
      questionId,
      year,
    });

    //handles scenario where a question has subquestions and we need to show data driven "question" footnotes
    if (response && response.data && response.data.footnoteData) {
      setFootnotes(await response.data.footnoteData);
      setShortNavFootNoteSymbol(await response.data.shortNavFootnoteSymbol);
    }

    if (
      response &&
      response.data &&
      response.data.tableData &&
      response.data.tableData.length > 0
    ) {
      //if we have question data, do something.
      let questionData =
        response &&
        response.data &&
        response.data.tableData &&
        (await {
          tableData: response.data.tableData,
          medianRangeData: response.data.medianRangeData
            ? response.data.medianRangeData
            : null,
          legendData: response.data.mapData
            ? response.data.mapData[0].KeyItems
            : null,
        }); //note data structure change
      //Set the table data
      setSubQuestionTableData(questionData);
      setSubQuestionCSVData(questionData);

      const QuestionId = questionData.tableData[0].QuestionId;
      const SubQuestionId = questionData.tableData[0].SubQuestionId;
      const Yr = questionData.tableData[0].Yr;
      navigate(
        `?questionId=${QuestionId}&LocationAbbr=ALL${
          SubQuestionId ? "&subQuestionId=" + SubQuestionId : ""
        }&year=${Yr}`
      );
    } else {
      //Clear the table data
      setSubQuestionTableData(null);
      setSubQuestionCSVData(null);

      //we don't have data. just set what was sent to the api
      navigate(
        `?questionId=${questionId}&LocationAbbr=ALL${
          subQuestionId ? "&subQuestionId=" + subQuestionId : ""
        }&year=${year}`
      );
    }
  }
  //----------------------------------------------------------------

  const locationAbbr =
    location && location.LocationAbbr ? location.LocationAbbr : null;
  const subQuestionId =
    subQuestion && subQuestion.SubQuestionId ? subQuestion.SubQuestionId : null;
  const questionId =
    question && question.QuestionId ? question.QuestionId : null;
  const topicId = question ? question.TopicId : false;
  const showReturnToLocationView =
    !subQuestionValue && location.LocationAbbr !== "ALL" ? true : false;
  const showReturnToSubQuestionView =
    question.SubQuestions.length > 0 &&
    location.LocationAbbr !== "ALL" &&
    subQuestionValue
      ? true
      : false;

  return (
    <>
      <div className="row m-3" id="dataviz">
        <div className="col-12 p-0">
          <QuestionHeader
            comparisonData={comparisonData}
            displayLocations={displayLocations}
            displayLocationDropdown={displayLocationDropdown}
            handleSubQuestionChange={handleSubQuestionChange}
            isPatternsOverTime={isPatternsOverTime}
            locations={locations}
            onVizTypeChange={onVizTypeChange}
            onYearChange={onYearChange}
            onLocationChange={onLocationChange}
            onReturnToLocationList={handleReturnToLocationList}
            onReturnToLocationSubQuestionList={
              handleReturnToLocationSubQuestionList
            }
            setComparisonData={setComparisonData}
            selectedData={selectedData}
            setLocationSelectedFromDropdownMenu={
              setLocationSelectedFromDropdownMenu
            }
            setIsPatternsOverTime={setIsPatternsOverTime}
            showReturnToLocationView={showReturnToLocationView}
            showReturnToSubQuestionView={showReturnToSubQuestionView}
            subQuestion={subQuestion}
            shortNavFootNoteSymbol={shortNavFootNoteSymbol}
          />

          {vizType === "table" && questionId && (
            <>
              <Table
                comparisonData={comparisonData}
                footnotes={footnotes}
                fetchTableData={fetchTableData}
                fetchTableDataForLocation={fetchTableDataForLocation}
                isPatternsOverTime={isPatternsOverTime}
                locationAbbr={locationAbbr}
                location={location}
                locationSelectedFromDropdownMenu={
                  locationSelectedFromDropdownMenu
                }
                onLocationChange={onLocationChange}
                onSubQuestionChange={handlePatternsOverTime}
                question={question}
                questionId={questionId}
                ref={tableRef}
                selectedData={selectedData}
                scrollToTableHeader={scrollToTableHeader}
                setIsPatternsOverTime={setIsPatternsOverTime}
                setSubQuestionTableData={setSubQuestionTableData}
                setSubQuestionCSVData={setSubQuestionCSVData}
                subQuestionId={subQuestionId}
                subQuestionTableData={subQuestionTableData}
                subQuestion={subQuestion}
                vizType={vizType}
                year={year}
              />
            </>
          )}

          {vizType === "map" && (
            <MapView
              location={location}
              onVizTypeChange={onVizTypeChange}
              question={question}
              subQuestion={subQuestion}
              subQuestionCSVData={subQuestionCSVData}
              vizType={vizType}
            />
          )}
        </div>
        <div>
          {(selectedData.question.SubQuestions.length === 0 ||
            selectedData.subQuestion !== null) && (
            <div className="row">
              <div className="col-3">
                <PrintPdf
                  topicId={topicId}
                  topicIdSet={topicId !== undefined}
                />
              </div>
              <div className="col-3">
                <ExportCsv
                  subQuestionCSVData={subQuestionCSVData}
                  topicIdSet={topicId !== undefined}
                  topicId={topicId}
                />
              </div>
            </div>
          )}
          <p />
          {footnotes.length > 0 && (
            <div id="footnotes">
              <h3>Footnotes</h3>
              {footnotes.map((i) => (
                <div style={{ marginBottom: 7 }}>
                  <span style={{ fontFamily: "arial", width: "35px" }}>
                    <span id="footnote-symbol">
                      {i.DataValueFootnoteSymbol}&nbsp;
                    </span>
                  </span>
                  <span id="footnote-text">{i.DataValueFootnote}</span>
                </div>
              ))}
              <hr />
            </div>
          )}

          <div>
            <p class="m-0">
              <b>Last Reviewed:</b> March 20, 2024
            </p>
            <p>
              <b>Source:</b> Division of Adolescent and School Health, National
              Center for Chronic Disease Prevention and Health Promotion
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataVisualization;
