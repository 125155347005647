let CDCBaseURL = "";
let APIBaseURL = "";
let ProfilesBaseURL = "";
let EnableAnalyticInteractions = false;
let EnableAnalyticConsoleLog = false;
const HOST_ENV = getHostEnvironment();

function getHostEnvironment() {
    var hostName = window.location.hostname;
    if (hostName.includes("localhost")) return "localhost";
    if (hostName.includes("profiles-explorer.apps.ecpaas-dev.cdc.gov")) return "dev";
    if (hostName.includes("-qa.apps.ecpaas-dev.cdc.gov")) return "qa";
    if (hostName.includes("-int.apps.ecpaas.cdc.gov")) return "int";
    return "prod";
}

switch (HOST_ENV) {
    case "localhost":
        //APIBaseURL = 'https://localhost:7291/api';
        APIBaseURL = 'https://profiles-explorer.apps.ecpaas-dev.cdc.gov/api';
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        ProfilesBaseURL = '/';
        EnableAnalyticInteractions = false;
        EnableAnalyticConsoleLog = false;
        break;
    case "dev":
        APIBaseURL = '/api'
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        ProfilesBaseURL = '/'
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = true;
        break;
    case "qa":
        APIBaseURL = '/api'
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        ProfilesBaseURL = '/'
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "int":
        APIBaseURL = "/api";
        CDCBaseURL = "https://www.cdc.gov/";
        ProfilesBaseURL = '/'
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "prod":
        APIBaseURL = "/api";
        CDCBaseURL = "https://www.cdc.gov/";
        ProfilesBaseURL = '/'        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    default:
}

module.exports = {
    APIBaseURL: APIBaseURL,
    CDCBaseURL: CDCBaseURL,
    ProfilesBaseURL: ProfilesBaseURL,
    EnableAnalyticInteractions: EnableAnalyticInteractions,
    EnableAnalyticConsoleLog: EnableAnalyticConsoleLog,
};
