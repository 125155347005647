import React from "react";
import { topicDictionary } from "../../Util/tileDictionary";
import * as analytics from "../../Util/Analytics/QuestionAnalytics";
const PrintPdf = ({ topicId, topicIdSet }) => {
  function handlePrint() {
    analytics.selectExport({ exportType: "PRINT" });
    window.print();
  }

  const color = topicIdSet ? topicDictionary[topicId].color : "";

  return (
    <>
      <button
        className="btn-info genBtn"
        onClick={handlePrint}
        style={{
          color: "white",
          width: "200px",
          backgroundColor: color,
        }}
      >
        Print/Save to PDF
      </button>
    </>
  );
};

export default PrintPdf;
