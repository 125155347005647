import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DashboardTile from "./DashboardTile";

const DashboardTiles = ({
  onVizTypeChange,
  questions,
  searchTerm,
  topicId,
}) => {
  const [hideAllSubquestions, setHideAllSubquestions] = useState(true);

  if (
    questions &&
    questions.length === 0 &&
    (searchTerm != null || topicId != null)
  )
    return "no results";
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 2, 1000: 3, 1200: 4 }}
    >
      <Masonry columnsCount={4} gutter="3px" className="masonry-column">
        {questions &&
          questions.length > 0 &&
          questions.map((question, i) => (
            <DashboardTile
              hideAllSubquestions={hideAllSubquestions}
              onVizTypeChange={onVizTypeChange}
              key={i}
              question={question}
              setHideAllSubquestions={setHideAllSubquestions}
            />
          ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default DashboardTiles;
