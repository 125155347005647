import React, { useEffect, useRef } from "react";
import geojson from "./usa-topo.json";
import { feature } from "topojson-client";
import * as d3 from "d3";
import "../scss/map.scss";
import * as tileDictionary from "../Util/tileDictionary";
import * as analytics from "../Util/Analytics/MapAnalytics";

let state = null;
let smallStateBoxItems = [];
let smallStateTextItems = [];

const Map = ({
  onVizTypeChange,
  question,
  subQuestion,
  subQuestionCSVData,
  vizType,
}) => {
  const card = null;
  const svgContainer = useRef();

  useEffect(() => {
    document.title =
      "School Health Profiles Explorer | Data Visualization: Map";
  }, []);

  useEffect(() => {
    const map = document.getElementById("subquestion-map-container");
    subQuestionCSVData && scrollToMap(map);
  }, [vizType, subQuestionCSVData]);

  const scrollToMap = (element) => {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  // removing tooltip when compenet unmounts
  useEffect(() => () => removeTooltip());

  useEffect(() => {
    removeTooltip();
    InitializeMapData(subQuestion, subQuestionCSVData);
  }, [subQuestionCSVData]);

  let zoom = d3.zoom().scaleExtent([1, 8]).on("zoom", zoomed);

  function InitializeMapData(classNumber, tableData) {
    tableData && ShowMap(geojson, tableData);
  }

  let ResetZoom = () => {
    zoom.transform(state, d3.zoomIdentity);
  };
  function ZoomIn() {
    zoom.scaleBy(state.transition().duration(300), 1.5);
  }
  function ZoomOut() {
    zoom.scaleBy(state.transition().duration(300), 0.9);
  }
  function zoomed(event) {
    const { transform } = event;
    state.attr("transform", transform);
    state.attr("stroke-width", 2 / transform.k);

    smallStateBoxItems.forEach((item, index) => {
      item.obj.attr("transform", transform);
      item.obj.attr("stroke-width", 2 / transform.k);
    });
    smallStateTextItems.forEach((item, index) => {
      item.obj.attr("transform", transform);
      item.obj.attr("stroke-width", 2 / transform.k);
    });
  }

  function getColor(currentLocation, data, tileDictionary) {
    let fillColor, textColor;
    const stateData = data.tableData.filter(
      (i) => i.LocationTxt === currentLocation
    );
    const percentage =
      stateData.length > 0 && stateData[0].NPercent !== null
        ? parseFloat(stateData[0].NPercent)
        : 0;
    const gradientIndex =
      percentage > 0
        ? data.legendData.findIndex(
            (i) =>
              percentage >= i.LowValue.toFixed(1) &&
              percentage <= i.HighValue.toFixed(1)
          ) + 1
        : 0;

    fillColor =
      stateData && stateData.length > 0 && gradientIndex >= 0
        ? tileDictionary.topicDictionary[stateData[0].TopicId].colors
            .slice()
            .reverse()[gradientIndex].fillColor
        : false;
    textColor =
      stateData && stateData.length > 0 && gradientIndex >= 0
        ? tileDictionary.topicDictionary[stateData[0].TopicId].colors
            .slice()
            .reverse()[gradientIndex].textColor
        : false;
    return [fillColor, textColor];
  }

  function ShowMap(mapData, data) {
    let svgScaleFactor = null;
    const clientWidth =
      svgContainer.current && svgContainer.current.clientWidth;

    switch (true) {
      case clientWidth < 550:
        svgScaleFactor = 1.8;
        break;
      case clientWidth >= 550 && clientWidth < 675:
        svgScaleFactor = 1.5;
        break;
      case clientWidth >= 675 && clientWidth < 850:
        svgScaleFactor = 1.4;
        break;
      case clientWidth >= 850 && clientWidth < 1030:
        svgScaleFactor = 1.2;
        break;
      case clientWidth >= 1030 && clientWidth < 1210:
        svgScaleFactor = 1.1;
        break;
      default:
        svgScaleFactor = 1;
    }

    const leftPadding = 40;
    const topPadding = 23;

    let width =
      svgContainer.current &&
      svgContainer.current.clientWidth * svgScaleFactor - 2 * leftPadding;
    let height = width * 0.6 - 2 * topPadding;

    d3.select("#subquestion-map-container svg").remove();
    const tableData = data && Array.isArray(data) ? data : data.tableData;
    const statesWithData = tableData.map((i) => i.LocationTxt);
    const svg = d3
      .select("#subquestion-map-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height + 85)
      .attr("style", "border: 1px solid #ddddd")
      .attr("viewBox", `-60, 0, ${width * svgScaleFactor}, ${height}`)
      .attr("preserveAspectRatio", "xMaxYMin meet")
      .attr("style", "width: 100%; height: auto; height: intrinsic;");

    /***********************************************************************************
     * Display state polygon for object is data and add
     * diamond at midpoint of each state with local data
     ***********************************************************************************/
    const localDataItems = data.tableData.filter(
      (i) => i.LocationType === "Local"
    );
    const localDataStates = Array.from(
      new Set(localDataItems.map((i) => i.ParentLocationDesc))
    );
    state = svg
      .append("g")
      .attr("id", "map")
      .attr("transform", "translate(0, 0)");
    state
      .attr("class", "states")
      .selectAll("g")
      .data(feature(mapData, mapData.objects.states).features)
      .join("g")
      .attr("id", (d) => d.properties.name.replace(/\s/g, "-"))
      .attr("class", (d) =>
        localDataStates.includes(d.properties.name)
          ? "state-group local-data"
          : "state-group"
      )
      .append("path")
      .attr("d", d3.geoPath())
      .attr("stroke-opacity", "1")
      .attr("stroke", "white")
      .attr("fill", "#ccc") // default fill (no data)
      .style("cursor", "pointer")
      .style("stroke-width", function (d) {
        return 1;
      })
      .style("fill", function (d) {
        if (statesWithData.includes(d.properties.name)) {
          return getColor(d.properties.name, data, tileDictionary)[0];
        }
      })
      .on("click", function (event, d) {
        removeTooltip();
        const textColor = getColor(d.properties.name, data, tileDictionary)[1];
        const fillColor = getColor(d.properties.name, data, tileDictionary)[0];
        event.currentTarget.classList.add("hovered");
        showTooltip(event, d, fillColor, textColor);
      });

    /***********************************************************************************
     * Generating local data symbols for larger states
     ************************************************************************************/
    const adjustSymbolSpacing = [
      { state: "Alaska", values: { x: -20, y: -30 } },
      { state: "Arkansas", values: { x: -17, y: 0 } },
      { state: "California", values: { x: -30, y: 20 } },
      { state: "Connecticut", values: { x: -9, y: -5 } },
      { state: "Florida", values: { x: 20, y: -33 } },
      { state: "Hawaii", values: { x: 30, y: -15 } },
      { state: "Idaho", values: { x: -10, y: 20 } },
      { state: "Iowa", values: { x: -23, y: 10 } },
      { state: "Maine", values: { x: -15, y: -2 } },
      { state: "Massachusetts", values: { x: -17, y: 3 } },
      { state: "Michigan", values: { x: 30, y: 0 } },
      { state: "Minnesota", values: { x: -23, y: 10 } },
      { state: "Missouri", values: { x: -17, y: 0 } },
      { state: "New Hampshire", values: { x: -7, y: 0 } },
      { state: "North Carolina", values: { x: -17, y: 0 } },
      { state: "North Dakota", values: { x: -13, y: 0 } },
      { state: "Oklahoma", values: { x: -3, y: -13 } },
      { state: "Pennsylvania", values: { x: -17, y: 0 } },
      { state: "South Carolina", values: { x: -10, y: -5 } },
      { state: "South Dakota", values: { x: -13, y: 0 } },
      { state: "Tennessee", values: { x: -17, y: 0 } },
      { state: "West Virginia", values: { x: -17, y: 10 } },
    ];

    const smallStateNames = [
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Massachusetts",
      "Rhode Island",
      "Hawaii",
      "Maryland",
    ];

    function generateLocalDataIcons(selector) {
      d3.selectAll(selector)
        .append("rect")
        .style("cursor", "pointer")
        .attr("fill", "white")
        .attr("stroke", "#666")
        .attr("stroke-width", 1)
        .on("click", (event, d) => {
          const textColor = getColor(
            d.properties.name,
            data,
            tileDictionary
          )[1];
          const fillColor = getColor(
            d.properties.name,
            data,
            tileDictionary
          )[0];
          event.currentTarget.classList.add("hovered");
          removeTooltip();
          showTooltip(event, d, fillColor, textColor);
        })
        .attr("height", 9)
        .attr("width", 9)
        .attr("x", (d, i, n) => {
          const box = d3
            .select(`#${d.properties.name.replace(/\s/g, "-")} path`)
            .node()
            .getBBox();
          const adjustedStates = adjustSymbolSpacing.map((i) => i.state);
          const adjustmentX = adjustedStates.includes(d.properties.name)
            ? adjustSymbolSpacing.find((i) => i.state === d.properties.name)
                .values.x
            : 0;
          return box.x + box.width + adjustmentX - box.width * 0.4;
        })
        .attr("y", (d, i, n) => {
          const box = d3
            .select(`#${d.properties.name.replace(/\s/g, "-")} path`)
            .node()
            .getBBox();
          const adjustedStates = adjustSymbolSpacing.map((i) => i.state);
          const adjustmentY = adjustedStates.includes(d.properties.name)
            ? adjustSymbolSpacing.find((i) => i.state === d.properties.name)
                .values.y
            : 0;
          return box.y + box.height * 0.5 + adjustmentY;
        })
        .attr("transform", (d) => {
          const box = d3
            .select(`#${d.properties.name.replace(/\s/g, "-")} path`)
            .node()
            .getBBox();
          const adjustedStates = adjustSymbolSpacing.map((i) => i.state);
          const adjustmentY = adjustedStates.includes(d.properties.name)
            ? adjustSymbolSpacing.find((i) => i.state === d.properties.name)
                .values.y
            : 0;
          const adjustmentX = adjustedStates.includes(d.properties.name)
            ? adjustSymbolSpacing.find((i) => i.state === d.properties.name)
                .values.x
            : 0;
          return `rotate(45 ${box.x + box.width - box.width * 0.45} ${
            box.y + box.height * 0.5
          })`;
        });
    }

    generateLocalDataIcons(".state-group.local-data");

    const nationDataBox_x = 0;
    const nationDataBox_y = 600;
    const nationDataBox_width = 65;
    const nationDataBox_height = 40;
    const nationalDataBox = svg
      .selectAll("#map")
      .append("g")
      .attr("id", "national-data");
    nationalDataBox
      .selectAll("rect")
      .data(tableData.filter((i) => i.LocationType === "National"))
      .join("rect")
      .attr("class", "states")
      .attr("x", () => {
        return nationDataBox_x;
      })
      .attr("y", (d, index) => nationDataBox_y + (index + 1) * 60)
      .attr("width", nationDataBox_width)
      .attr("height", nationDataBox_height)
      .style("fill", function (d) {
        return getColor(d.LocationTxt, data, tileDictionary)[0];
      })
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("id", (d) => d.id)
      .style("stroke", "grey")
      .style("stroke-width", function (d) {
        return 1;
      })
      .on("click", function (event, d) {
        const textColor = getColor(d.LocationTxt, data, tileDictionary)[1];
        const fillColor = getColor(d.LocationTxt, data, tileDictionary)[0];
        event.currentTarget.classList.add("hovered");
        let item = { properties: { name: "" } };
        item.properties.name = d.LocationTxt;
        removeTooltip();
        showTooltip(event, item, fillColor, textColor);
      });

    const nationalDataBoxLabel = svg
      .selectAll("#national-data")
      .append("g")
      .attr("id", "national-data-label")
      .attr("x", nationDataBox_x + nationDataBox_width * 0.5 - 10)
      .attr(
        "y",
        (d, index) =>
          nationDataBox_y + (index + 1) * 60 + nationDataBox_height * 0.5 + 7
      );

    nationalDataBoxLabel
      .selectAll("text")
      .data(tableData.filter((i) => i.LocationType === "National"))
      .join("text")
      .text((d) => "US")
      .style("pointer-events", "none")
      .attr("fill", (d) => getColor(d.LocationTxt, data, tileDictionary)[1])
      .attr("stroke", (d) => getColor(d.LocationTxt, data, tileDictionary)[1])
      .attr("stroke-width", ".5")
      .attr("x", nationDataBox_x + nationDataBox_width * 0.5 - 10)
      .attr(
        "y",
        (d, index) =>
          nationDataBox_y + (index + 1) * 60 + nationDataBox_height * 0.5 + 7
      );

    /*************************************
     * Adding legend
     *************************************/
    d3.selectAll("#legend-group").remove(); // Remove legend for previous subquestion
    const legendHeight = 248,
      legendWidth = 140;

    const legendGroup = d3.select("#subquestion-map-container").append("div");

    legendGroup
      .attr("id", "legend-group")
      .append("div")
      .attr("id", "legend-label")
      .text("Percent (%)")
      .attr("font-weight", "bold")
      .attr("font-size", "17px")
      .attr("fill", "black")
      .attr("x", legendWidth / 2 - 60)
      .attr("y", 32);

    const legendDataLabels = data.legendData
      .slice()
      .reverse()
      .map((i) => i.FullText);
    legendDataLabels.push("No data");
    legendDataLabels.push("Local data available");

    const valueContainer = d3.select("#legend-group").append("div");
    valueContainer.attr("id", "value-container");
    const valueGroups = valueContainer.append("div");
    const valueColors = tileDictionary.topicDictionary[question.TopicId].colors;
    valueGroups.attr("id", "value-groups");

    valueGroups
      .selectAll("div")
      .data(legendDataLabels)
      .join("div")
      .attr("class", "value-group")
      .html(
        (d, index) =>
          `<div class='small-circle' style="background-color:${valueColors[index] && valueColors[index].fillColor};"></div><div class="value-label">${d}</div>`
      );

    d3.select("#value-groups .value-group:last-child .small-circle")
      .append("div")
      .attr("class", "diamond");

    const smallTerritory_x = 0;
    const smallTerritory_y = 650;
    const smallTerritory_width = 60;
    const smallTerritory_height = 35;

    const smallStateItemBox = svg
      .select("#map")
      .append("g")
      .attr(
        "transform",
        `translate(${
          width * svgScaleFactor - (legendWidth + 3) - 155
        }, ${-430})`
      )
      .attr("id", "small-states-group");

    /*****************************************
     * Generating rectangles for small states
     ******************************************/
    const smallStates = mapData.objects.states.geometries.filter((d) => {
      return smallStateNames.includes(d.properties.name);
    });
    smallStates.sort((a, b) =>
      a.properties.name > b.properties.name ? 1 : -1
    );
    smallStates.forEach((smallState, index) => {
      const stateObject = tableData.filter(
        (i) => i.LocationTxt === smallState.properties.name
      )[0];
      smallStateItemBox
        .append("g")
        .attr("id", smallState.properties.name.replace(/\s/g, "-"))
        .attr("class", "small-states")
        .append("rect")
        .attr("x", smallTerritory_x)
        .attr("y", smallTerritory_y + index * 47)
        .attr("rx", 5)
        .attr("ry", 5)
        .style("stroke", "grey")
        .style("fill", () => {
          return getColor(smallState.properties.name, data, tileDictionary)[0];
        })
        .style("stroke-width", function (d) {
          return 1.5;
        })
        .attr("width", smallTerritory_width)
        .attr("height", smallTerritory_height)
        .style("stroke", "grey")
        .on("click", function (event, d) {
          const textColor = getColor(
            smallState.properties.name,
            data,
            tileDictionary
          )[1];
          const fillColor = getColor(
            smallState.properties.name,
            data,
            tileDictionary
          )[0];
          stateObject.properties = { name: stateObject.LocationTxt };
          removeTooltip();
          showTooltip(event, smallState, fillColor, textColor);
        });

      /*****************************************
       * Generating text for smaller states
       ******************************************/
      const smallStateItemText = smallStateItemBox
        .append("text")
        .attr("class", "territory-text")
        .attr("x", smallTerritory_x + 23)
        .attr("y", smallTerritory_y + index * 47 + 25)
        .text(() => stateObject.LocationAbbr)
        .attr("text-anchor", "middle")
        .style("fill", () => {
          return getColor(smallState.properties.name, data, tileDictionary)[1];
        })
        .attr(
          "font-family",
          ' "Open Sans",apple-system,blinkmacsystemfont,"Segoe UI","Helvetica Neue",arial,sans-serif'
        )
        .style("font-size", "15px")
        .style("font-weight", "bold")
        .style("pointer-events", "none");

      /*********************************************
       * Generating Local data symbols for small states
       *********************************************/
      function createLocalDataIcons() {
        d3.select(
          `.small-states#${smallState.properties.name.replace(/\s/g, "-")}`
        )
          .append("rect")
          .style("cursor", "pointer")
          .attr("fill", "white")
          .attr("stroke", "#666")
          .attr("stroke-width", 1)
          .attr("height", 9)
          .attr("width", 9)
          .attr("x", smallTerritory_x + 44)
          .attr("y", smallTerritory_y + index * 47 + 15)
          .on("click", (event) => {
            const textColor = getColor(
              smallState.properties.name,
              data,
              tileDictionary
            )[1];
            const fillColor = getColor(
              smallState.properties.name,
              data,
              tileDictionary
            )[0];
            event.currentTarget.classList.add("hovered");
            removeTooltip();
            showTooltip(event, smallState, fillColor, textColor);
          })
          .attr("transform", (d) => {
            return `rotate(45 ${smallTerritory_x + 44 + 4.5} ${
              smallTerritory_y + index * 47 + 15 + 4.5
            })`;
          });
      }
      if (
        stateObject.LocationType === "Local" ||
        localDataStates.includes(stateObject.LocationTxt)
      )
        createLocalDataIcons();
    });

    svg.call(zoom);
  }

  function showTooltip(event, i, fillColor, textColor) {
    const filteredTableData =
      subQuestionCSVData && Array.isArray(subQuestionCSVData)
        ? subQuestionCSVData.filter(function (DataItem) {
            return (
              DataItem.LocationTxt === i.properties.name ||
              DataItem.ParentLocationDesc === i.properties.name
            );
          })
        : subQuestionCSVData.tableData.filter(function (DataItem) {
            return (
              DataItem.LocationTxt === i.properties.name ||
              DataItem.ParentLocationDesc === i.properties.name
            );
          });

    /**********************************************************
     * Prevent error in 'no data' usecase by adding
     * at leat one item to filteredTableData where
     * there is no data.
     **********************************************************/
    filteredTableData.length === 0 && filteredTableData.push(i);
    /**-------------------------------------------------------- */

    const showMultiTooltip = filteredTableData.length > 1;

    const topicColor =
      tileDictionary.topicDictionary[question.TopicId].colors[0].fillColor;

    const tooltipContainerElement = showMultiTooltip
      ? "#subquestion-map-container"
      : "body";
    const tooltip = d3
      .select(tooltipContainerElement)
      .append("div")
      .attr("id", () => {
        const id = showMultiTooltip ? "multi-tooltip" : "tooltip";
        return id;
      })
      .attr("class", "tooltip")
      .style("position", () => "absolute")
      .style("opacity", "1")
      .style(
        "top",
        () => `${showMultiTooltip ? "17%" : event.pageY - 60 + "px"}`
      )
      .style("left", () => {
        const svgWidth = svgContainer.current.clientWidth;
        const value =
          event.pageX > svgWidth - 250
            ? `${
                showMultiTooltip
                  ? "30%"
                  : event.pageX -
                    parseInt(d3.select(".tooltip").node().clientWidth) * 1.1 +
                    "px"
              }`
            : `${showMultiTooltip ? "30%" : event.pageX + 11 + "px"}`;
        return value;
      });

    tooltip
      .append("div")
      .attr("class", "tooltip-close-container")
      .style("display", () => (showMultiTooltip ? "flex" : "none"))
      .html(() => '<span class="cdc-icon-close close-tooltip" />');

    const tooltip_content = tooltip.append("div").attr("id", "tooltip-content");

    tooltip_content
      .selectAll("section")
      .data(filteredTableData)
      .join("section")
      .attr("class", (item) => item.LocationType)
      .attr("id", (item, index) => `location_${index}`)
      .html((item) => {
        const tooltipColor = getColor(
          item.LocationTxt,
          subQuestionCSVData,
          tileDictionary
        );
        const dataString =
          Object.keys(item).includes("LocationTxt") && item.NPercent !== null
            ? `
              <div class="tooltip-header"
              style="background-color:
                ${tooltipColor[0]};
                display:flex;
                justify-content:space-between;">
                <span 
                  style="font-size:1rem;
                  font-weight:bold;
                  color:${tooltipColor[1]};"
                >
                ${item.LocationTxt}</span>
                ${
                  showMultiTooltip
                    ? ""
                    : `<span class="cdc-icon-close close-tooltip" style="color:${tooltipColor[1]};" />`
                }
              </div>
              <div class="tooltip-data">
                <div><b>%: ${item.NPercent}</b></div>
                <div>CI: ${item.ConfidenceInterval}</div>
                <div>N: ${item.SampleSize}</div>
                <div
                  style="font-size:.65rem;cursor:pointer"
                  id="tooltip-link"
                  data=${item.LocationAbbr}
                >
                  View ${item.LocationTxt} Survey Results
                </div>
              </div>`
            : `<div class="tooltip-data" style="color:black;display:flex;"><span > <b>${i.properties.name}</b>: No data </span><span class="cdc-icon-close close-tooltip" style="padding-left: 17px;font-weight:bold;"/></div>`;
        return dataString;
      });

    const displayStateHeader = filteredTableData
      .map((datum) => datum.LocationType)
      .includes("State");

    const displayLocalHeader = filteredTableData
      .map((datum) => datum.LocationType)
      .includes("Local");

    d3.select("#tooltip-content")
      .style("padding", () =>
        !showMultiTooltip && displayLocalHeader ? "8px" : ""
      )
      .insert("span", ".State")
      .attr("class", "location-type-header")
      .attr("id", "state-header")
      .style("display", () =>
        showMultiTooltip && displayStateHeader ? "block" : "none"
      )
      .style("color", topicColor)
      .html("State Surveys");

    d3.select("#tooltip-content")
      .insert("span", ".Local")
      .attr("class", "location-type-header")
      .attr("id", "local-header")
      .style("display", () => (displayLocalHeader ? "block" : "none"))
      .style("color", topicColor)
      .html("Local Surveys");

    tooltip.selectAll(".close-tooltip").on("click", removeTooltip);
    tooltip.selectAll("#tooltip-link").on("click", (e) => {
      analytics.SelectMapLink();
      removeTooltip();
      onVizTypeChange({
        vizType: "table",
        questionId: question.QuestionId,
        subQuestionId: subQuestion ? subQuestion.SubQuestionId : null,
        locationAbbr: e.target.getAttribute("data"), // returns abreviation for state and local objects
      });
    });
  }

  function removeTooltip() {
    d3.select(".tooltip").style("opacity", "0");
    d3.select(".tooltip").remove();
  }

  return (
    subQuestionCSVData && (
      <>
        <div
          className="text-center"
          ref={svgContainer}
          id="subquestion-map-container"
        >
          <div id="map-controls">
            <div className="map-control" onClick={ZoomIn} id="zoom-in">
              <span class="cdc-icon-plus"></span>
            </div>
            <div className="map-control" onClick={ZoomOut} id="zoom-out">
              <span class="cdc-icon-minus"></span>
            </div>

            <div className="map-control" onClick={ResetZoom} id="zoom-reset">
              <span class="cdc-icon-replay"></span>
            </div>
          </div>
          <h2
            className="question-header__location-text"
            style={{
              fontFamily: "sans-serif !important",
              color: tileDictionary.topicDictionary[question.TopicId].color,
            }}
          >
            {subQuestion
              ? subQuestion.SubQuestion
              : question.LongQuestionDescription}
          </h2>
        </div>
      </>
    )
  );
};

export default Map;
