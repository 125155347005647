import React, { useState, useRef } from "react";
import * as analytics from "../Util/Analytics/QuestionAnalytics";
const ShareLink = () => {
  const linkRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openShare = () => {
    analytics.selectShareLink();
    setModalIsOpen(true);
  };

  const closeShare = () => {
    setModalIsOpen(false);
  };

  function handleCopy() {
    linkRef.current.select();
    navigator.clipboard.writeText(window.location.href);
    closeShare();
  }

  function handleModalShow() {
    linkRef.current.select();
  }
  return (
    <div className="col-12 p-0 m-0 shareLink__share-text">
      <button className="btn btn-link" onClick={openShare} role="link">
        <span class="cdc-icon-share-solid" style={{ fontSize: "25px" }}></span>{" "}
        Share Link
      </button>
      {modalIsOpen && (
        <>
          <div id="modal-backdrop" class="modal-backdrop"></div>
          <div
            class={"modal fade show"}
            onMouseEnter={handleModalShow}
            style={{ display: "block" }}
            data-backdrop="static"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="false"
          >
            <div class="modal-dialog modal-lg" role="document" tabIndex="1">
              <div class="modal-content">
                <div class="modal-header">
                  <div className="modal-header-text">Share Link</div>
                  <button
                    type="button"
                    aria-label="Close"
                    onClick={closeShare}
                    tabIndex="1"
                  >
                    <span class="cdc-icon-close"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-12 text-left">
                      <input
                        className="form-control"
                        readOnly
                        ref={linkRef}
                        type="text"
                        value={window.location.href}
                        autofocus
                      ></input>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div className="row">
                    <div className="col-6 text-left">Copy and paste URL</div>
                    <div className="col-6">
                      <button
                        type="button"
                        class="btn button copy"
                        style={{
                          backgroundColor: "rgb(113, 33, 119)",
                          color: "white",
                        }}
                        data-
                        dismiss="modal"
                        onClick={handleCopy}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ShareLink;
