import React, { useEffect, useState } from "react";
import { topicDictionary } from "../Util/tileDictionary";
import * as d3 from "d3";
import * as analytics from "../Util/Analytics/TileAnalytics";
const DashboardTile = ({
  hideAllSubquestions,
  onVizTypeChange,
  question,
  setHideAllSubquestions,
}) => {
  function showSubquestions() {
    showSubquestion ? setShowSubquestion(false) : setShowSubquestion(true);
  }

  function hideSubquestions() {
    d3.selectAll(".option-container").attr("style", "visibility:hidden");
    d3.selectAll(".default").html("View All");
    hideAllSubquestions
      ? setHideAllSubquestions(false)
      : setHideAllSubquestions(true);
  }
  useEffect(() => {
    d3.select("#App").on("click", hideSubquestions);
  }, []);

  useEffect(() => {
    setShowSubquestion(false);
    setHideAllSubquestions(!hideAllSubquestions);
  }, [question]);

  function handleOnChange(value) {
    setSubQuestionId(value);
  }

  const [subQuestionId, setSubQuestionId] = useState(null);
  const [showSubquestion, setShowSubquestion] = useState(false);

  function handleTableNavClick() {
    analytics.selectTable();
    onVizTypeChange({
      vizType: "table",
      questionId: question.QuestionId,
      subQuestionId: subQuestionId,
      locationAbbr: null,
    });

    //TODO: ADOBE-ANALYTICS: table view, question id, subquestion id
  }
  function handleMapNavClick() {
    analytics.selectMap();
    onVizTypeChange({
      vizType: "map",
      questionId: question.QuestionId,
      subQuestionId: subQuestionId,
      location: null,
    });

    //TODO: ADOBE-ANALYTICS: map view, question id, subquestion id
  }
  const i = topicDictionary;
  const icon = i[question.TopicId]
    ? i[question.TopicId].icon
    : "topic-icon fs1 mx-1 cdc-icon-school-solid";

  function setOption(option) {
    analytics.selectSubQuestion({
      questionId: question.QuestionId,
      subQuestionId: option.SubQuestionId,
    });
    hideSubquestions();
    const defaultValue = d3.select(`#${question.QuestionId}-default-value`);
    defaultValue.html(option.SubQuestion.substring(0, 13) + "...");
    handleOnChange(option.SubQuestionId);
  }
  return (
    <div
      aria-label="Question"
      className={"masonry-tile__wrapper yrbs-cat__color--" + question.TopicId}
      role="link"
      style={{
        backgroundColor: topicDictionary[question.TopicId].color,
      }}
    >
      <div
        onClick={handleTableNavClick}
        onKeyDown={(e) => {
          e.code === "Enter" && handleTableNavClick();
        }}
        style={{ cursor: "pointer" }}
        tabIndex="0"
        role="button"
      >
        <div className="tile-header align-items-center row m-0 p-2">
          <div className="col-2 masonry-tile__icon p-0">
            <span className={"topic-icon " + icon}></span>
          </div>
          <div className="col-10 pl-2">
            <div className="topic-description">{question.TopicDescription}</div>
            <div className="subtopic-description">
              {question.SubTopicDescription}
            </div>
          </div>
        </div>
        <p className="question-description p-2" style={{ cursor: "pointer" }}>
          {question.ShortQuestionDescription}
        </p>
      </div>
      <div className="masonry-tile__links pl-1 pr-1" style={{}}>
        {/* ---------------------------------------------- */}
        {question.SubQuestions && question.SubQuestions.length > 0 && (
          <>
            <p className="sub-question-text p-2">Select a Subquestion</p>
            <div className="pl-0">
              <div
                role="combobox"
                aria-label="select-subquestion"
                aria-expanded="false"
                aria-controls={`${question.QuestionId}-container`}
                tabIndex="0"
                className="custom-select"
                onChange={handleOnChange}
                onClick={() => {
                  showSubquestions();
                }}
                onKeyDown={(e) => {
                  e.code === "Enter" && showSubquestions();
                }}
                defaultValue="ALL"
              >
                <div
                  class="option default"
                  value="ALL"
                  id={`${question.QuestionId}-default-value`}
                  aria-label="subquestion list"
                >
                  View All
                </div>
                <div
                  role="listbox"
                  class="option-container"
                  id={`${question.QuestionId}-container`}
                  style={
                    showSubquestion
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  {question.SubQuestions &&
                    question.SubQuestions.map((option, index) => {
                      return (
                        <div
                          role="option"
                          aria-selected="false"
                          tabIndex="0"
                          class="option"
                          id={`${question.QuestionId}-option${index + 1}`}
                          key={option.SubQueestionId}
                          value={option.SubQuestionId}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOption(option);
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            e.code === "Enter" && setOption(option);
                          }}
                        >
                          {option.SubQuestion}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        )}
        {/* ------------------------------------- */}
        <div>
          <div className="row table-link-row">
            <div className="col-s-6 table-link">
              <button
                tabIndex="0"
                className="btn btn-link "
                onClick={handleTableNavClick}
              >
                <span className="cdc-icon-table-light"></span> Table
              </button>
            </div>
            <div className="col-m-6 col-s-6 map-link">
              <button
                tabIndex="0"
                className="btn btn-link"
                onClick={handleMapNavClick}
              >
                <span className="cdc-icon-map_02"></span> Map
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTile;
