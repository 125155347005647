import React from "react";
import { topicDictionary } from "../../Util/tileDictionary";
import * as analytics from "../../Util/Analytics/QuestionAnalytics";
const ExportCsv = ({ subQuestionCSVData, topicId, topicIdSet }) => {
  function handleExport() {
    analytics.selectExport({ exportType: "CSV" });
    // Create csv data
    const data = Object.keys(subQuestionCSVData).includes("medianRangeData")
      ? subQuestionCSVData.tableData
      : subQuestionCSVData;

    const keys = Object.keys(data[0]);
    const fileName = "Profiles Explorer Survey Questions.csv";
    const csvData =
      keys.join(",") +
      "\n" +
      data
        .map((obj, index) => {
          return keys
            .map((key) => {
              const value = obj[key];

              // Format the value if needed
              return value ? JSON.stringify(value) : "";
            })
            .join(",");
        })
        .join("\n");

    // Create blob from csv
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;

    // Trigger the download
    downloadLink.click();
  }
  const color = topicIdSet ? topicDictionary[topicId].color : "";
  return (
    <div>
      <button
        className="btn-info genBtn"
        onClick={handleExport}
        style={{
          color: "white",
          width: "200px",
          backgroundColor: color,
        }}
      >
        Export to CSV
      </button>
    </div>
  );
};

export default ExportCsv;
