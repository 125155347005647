import React, { useEffect, useState } from "react";
import * as analytics from './Analytics/SharedAnalytics'
const ScrollToTop = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showScroll, setShowScroll] = useState(false);

  function handleScrollToTop() {
      analytics.selectScrollToTop();
      window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleScroll = ({ topicId }) => {
    

    let scrollHeight = window.scrollY || window.pageYOffset;
    let maxHeight = window.document.documentElement.clientHeight;
    return scrollHeight > maxHeight
      ? setShowScroll(true)
      : setShowScroll(false);
  };

  return (
    <div
      // aria-hidden="true"
      className={
        showScroll ? "d-print-none row justify-content-center" : "d-none"
      }
    >
      <button
        onClick={handleScrollToTop}
        className="btn back-to-top"
        aria-label="back to top button"
      >
        <svg
          version="1.1"
          baseProfile="full"
          width="24"
          height="24"
          viewBox="0 0 24.00 24.00"
          enableBackground="new 0 0 24.00 24.00"
        >
          <path
            fill="white"
            fillOpacity="1"
            strokeWidth="0.2"
            strokeLinejoin="round"
            d="M 14,20L 10,20L 9.99998,10.9896L 6.49479,14.4948L 4.08058,12.0806L 12,4.16116L 19.9194,12.0806L 17.5052,14.4948L 14,11L 14,20 Z "
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default ScrollToTop;
