import React, { useEffect, useRef } from "react";
import SubQuestionTableRow from "./SubQuestionTableRow";
import { topicDictionary } from "../../Util/tileDictionary";

const SubQuestionTable = ({
  allLocations,
  comparisonData,
  isPatternsOverTime,
  linkFirstColumn,
  onLocationChange,
  onSubQuestionChange,
  parentLocation,
  question,
  scrollToTableHeader,
  setIsPatternsOverTime,
  subQuestion,
  subQuestionTableData,
  tableName,
  tableHeaderRef,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (scrollToTableHeader) {
      tableHeaderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [scrollToTableHeader]);

  const tableRowData =
    subQuestionTableData !== null && !Array.isArray(subQuestionTableData)
      ? subQuestionTableData.tableData
      : subQuestionTableData;

  return (
    <div>
      {subQuestionTableData !== null &&
      typeof subQuestionTableData === "object" ? (
        <>
          <div
            className="table-responsive"
            role="region"
            aria-labelledby="cap1"
            tabIndex="0"
          >
            <table
              ref={ref}
              className="table table-striped table-sm"
              style={{ borderCollapse: "collapse" }}
            >
              <caption id="cap1"></caption>
              <thead>
                {comparisonData && (
                  <tr>
                    <th></th>
                    {comparisonData && comparisonData.length >= 1 && (
                      <>
                        <th
                          className="th"
                          colSpan="3"
                          style={{
                            backgroundColor:
                              topicDictionary[question.TopicId].color,
                            color: "white",
                          }}
                        >
                          {parentLocation.LocationDesc}
                        </th>
                        <th
                          className="th"
                          colSpan="3"
                          style={{
                            backgroundColor:
                              topicDictionary[question.TopicId].color,
                            color: "white",
                            border: "none",
                          }}
                        >
                          {comparisonData[0].LocationTxt}
                        </th>
                      </>
                    )}
                  </tr>
                )}
                <tr>
                  <th
                    className="th__nobackground"
                    style={{
                      color: "#555",
                      fontSize: "25px",
                      width: "40%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {isPatternsOverTime ? (
                      ""
                    ) : (
                      <span className="m-0">{tableName}</span>
                    )}
                  </th>
                  <th
                    style={{
                      backgroundColor: topicDictionary[question.TopicId].color,
                      color: "white",
                      border: "none",
                    }}
                    className={comparisonData ? `` : "th"}
                  >
                    %
                  </th>
                  <th
                    style={{
                      backgroundColor: topicDictionary[question.TopicId].color,
                      color: "white",
                      border: "none",
                    }}
                    className={comparisonData ? `` : "th"}
                  >
                    CI
                  </th>
                  <th
                    style={{
                      backgroundColor: topicDictionary[question.TopicId].color,
                      color: "white",
                      border: "none",
                    }}
                    className={comparisonData ? `` : "th"}
                  >
                    N
                  </th>
                  {comparisonData && (
                    <>
                      <th className={comparisonData ? `` : "th"}>%</th>
                      <th className={comparisonData ? `` : "th"}>CI</th>
                      <th className={comparisonData ? `` : "th"}>N</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(subQuestionTableData) ? (
                  tableRowData.map((subQuestionData, i) => (
                    <>
                      <SubQuestionTableRow
                        allLocations={allLocations}
                        comparisonData={comparisonData}
                        isPatternsOverTime={isPatternsOverTime}
                        key={i}
                        linkFirstColumn={linkFirstColumn}
                        onLocationChange={onLocationChange}
                        onSubQuestionChange={onSubQuestionChange}
                        setIsPatternsOverTime={setIsPatternsOverTime}
                        subQuestionData={subQuestionData}
                      ></SubQuestionTableRow>
                    </>
                  ))
                ) : (
                  <>
                    {tableRowData.map((subQuestionData, i) => {
                      return (
                        <SubQuestionTableRow
                          key={i}
                          allLocations={allLocations}
                          comparisonData={comparisonData}
                          onLocationChange={onLocationChange}
                          subQuestionData={subQuestionData}
                          linkFirstColumn={linkFirstColumn}
                          onSubQuestionChange={onSubQuestionChange}
                          isPatternsOverTime={isPatternsOverTime}
                        ></SubQuestionTableRow>
                      );
                    })}

                    {tableRowData.length > 1 && (
                      <SubQuestionTableRow
                        allLocations={allLocations}
                        comparisonData={comparisonData}
                        isPatternsOverTime={isPatternsOverTime}
                        key={1}
                        linkFirstColumn={linkFirstColumn}
                        onLocationChange={onLocationChange}
                        onSubQuestionChange={onSubQuestionChange}
                        printRange={true}
                        subQuestionData={subQuestionTableData.medianRangeData.filter(
                          (i) => i.LocationType === tableRowData[0].LocationType
                        )}
                      ></SubQuestionTableRow>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        subQuestion === null &&
        !Array.isArray(subQuestionTableData) &&
        subQuestionTableData &&
        subQuestionTableData.hasOwnProperty("tableData") &&
        subQuestionTableData.tableData.length === 0 && <></>
      )}
    </div>
  );
};

export default SubQuestionTable;
