import React, { useEffect, useRef } from "react";
import SubQuestionTable from "./TableTypes/SubQuestionTable";
import { topicDictionary } from "../Util/tileDictionary";
const Table = ({
  comparisonData,
  fetchTableDataForLocation,
  isPatternsOverTime,
  locationAbbr,
  location,
  locationSelectedFromDropdownMenu,
  onLocationChange,
  onSubQuestionChange,
  question,
  questionId,
  scrollToTableHeader,
  selectedData,
  setIsPatternsOverTime,
  subQuestion,
  subQuestionId,
  subQuestionTableData,
  vizType,
  year,
}) => {
  const tableHeaderRef = useRef(null);
  const delayedDisplay = useRef("none");

  useEffect(() => {
    document.title = `School Health Profiles Explorer | 
    ${location.LocationDesc + " Results"} 
    `;
  }, [location]);

  useEffect(() => {
    delayedDisplay.current = "none";

    setTimeout(() => (delayedDisplay.current = "block"), 1000);
  }, [location, isPatternsOverTime]);

  setTimeout(() => (delayedDisplay.current = "block"), 500);

  const allLocations = locationAbbr === "ALL" ? true : false;

  // NOTE: This test fails when location !== "ALL"
  if (
    locationAbbr === "ALL" &&
    subQuestionTableData !== null &&
    !Array.isArray(subQuestionTableData) &&
    subQuestionTableData.hasOwnProperty("tableData") &&
    subQuestionTableData.tableData.length > 0 &&
    location.LocationType !== "National"
  ) {
    subQuestionTableData.tableData[0].LocationType =
      locationAbbr === "ALL"
        ? "State"
        : subQuestionTableData.tableData[0].LocationType;

    subQuestionTableData.tableData[0].LocationAlias =
      locationAbbr === "ALL" && "National";
  }
  const locationTypes =
    subQuestionTableData &&
    subQuestionTableData.hasOwnProperty("tableData") &&
    subQuestionTableData.tableData.length > 0 &&
    location.LocationAbbr === "ALL"
      ? Array.from(
          new Set(subQuestionTableData.tableData.map((i) => i.LocationType))
        )
      : [location.LocationType];

  const tableTitles = ["State Survey", "Local Survey", "Territory Survey"];

  const getTableTitle = (locationArrayIndex) => {
    const title =
      location.LocationAbbr !== "ALL"
        ? `${location.LocationDesc} Survey Results`
        : tableTitles[locationArrayIndex];
    return title;
  };

  const hasTableData = (dataObj) => {
    if (dataObj && dataObj.hasOwnProperty("tableData")) {
      return dataObj.tableData.length > 0 ? true : false;
    }
    return true;
  };

  return (
    <>
      <div className="row" ref={tableHeaderRef}>
        <div className="col-12 question-header__location-text">
          {!isPatternsOverTime && vizType === "table" && subQuestion && (
            <h2
              className="mt-4 mb-4 main-table-header"
              style={{
                color: topicDictionary[question.TopicId].color,
                fontSize: "30px",
              }}
            >
              {subQuestion.SubQuestion}
            </h2>
          )}
        </div>
      </div>
      {
        /****************************************
         * Scenario 1: Display all states Table
         * for items with no subquestions
         ************************************/
        question &&
          question.SubQuestions.length === 0 &&
          location.LocationAbbr === "ALL" &&
          subQuestionTableData &&
          subQuestionTableData.tableData &&
          locationTypes.map((type, index) => {
            const data = {
              tableData: subQuestionTableData.tableData.filter((i) => {
                return i.LocationType === type;
              }),
              medianRangeData: subQuestionTableData.medianRangeData,
            };
            const title = getTableTitle(index);
            return (
              <div id={`${type}-table`}>
                <SubQuestionTable
                  key={`table-${index}`}
                  isScrollToTop={false}
                  parentLocation={location}
                  locationAbbr={locationAbbr}
                  subQuestionId={subQuestionId}
                  questionId={questionId}
                  year={year}
                  title={"scenario 1"}
                  tableName={title}
                  question={question}
                  subQuestion={subQuestion}
                  subQuestionTableData={data}
                  allLocations={allLocations}
                  linkFirstColumn={true}
                  comparisonData={comparisonData}
                  onLocationChange={onLocationChange}
                  fetchTableDataForLocation={fetchTableDataForLocation}
                  isPatternsOverTime={isPatternsOverTime}
                  setIsPatternsOverTime={setIsPatternsOverTime}
                />
              </div>
            );
          })
      }
      {
        /****************************************
         * Scenario 2: Display all states table
         * for items WITH sub-questions
         ************************************/
        subQuestionTableData !== null &&
          question &&
          !isPatternsOverTime &&
          question.SubQuestions.length > 0 &&
          subQuestionTableData.hasOwnProperty("tableData") &&
          locationTypes.map((type, index) => {
            const data = {
              tableData: subQuestionTableData.tableData.filter((i) => {
                return i.LocationType === type;
              }),
              medianRangeData: subQuestionTableData.medianRangeData,
            };
            const title = getTableTitle(index);
            return (
              <div id={`${type}-table`}>
                <SubQuestionTable
                  allLocations={allLocations}
                  comparisonData={comparisonData}
                  fetchTableDataForLocation={fetchTableDataForLocation}
                  isPatternsOverTime={isPatternsOverTime}
                  linkFirstColumn={true}
                  locationAbbr={locationAbbr}
                  onLocationChange={onLocationChange}
                  parentLocation={location}
                  question={question}
                  questionId={questionId}
                  tableName={title}
                  tableHeaderRef={tableHeaderRef}
                  subQuestionId={subQuestionId}
                  subQuestion={subQuestion}
                  subQuestionTableData={data}
                  scrollToTableHeader={
                    type === "State" && scrollToTableHeader ? true : false
                  }
                  tableIndex={index}
                  title={"scenario 2"}
                  year={year}
                />
              </div>
            );
          })
      }
      {
        /****************************************
         * Scenario 3: Display all subquestions
         * associated with a specific location
         ************************************/
        location.LocationAbbr !== "ALL" &&
          subQuestionTableData &&
          !isPatternsOverTime &&
          !subQuestionTableData.hasOwnProperty("tableData") &&
          subQuestionTableData.length > 0 && (
            <div id={`${location.LocationType}-table`}>
              <SubQuestionTable
                allLocations={allLocations}
                comparisonData={comparisonData}
                fetchTableDataForLocation={fetchTableDataForLocation}
                isScrollToTop={true}
                isPatternsOverTime={isPatternsOverTime}
                linkFirstColumn={true}
                locationAbbr={locationAbbr}
                onLocationChange={onLocationChange}
                onSubQuestionChange={onSubQuestionChange}
                parentLocation={location}
                questionId={questionId}
                question={question}
                scrollToTableHeader={true}
                subQuestionId={subQuestionId}
                subQuestion={subQuestion}
                subQuestionTableData={subQuestionTableData}
                tableName={``}
                tableHeaderRef={tableHeaderRef}
                title={"scenario 3"}
                year={year}
              />
            </div>
          )
      }
      {
        /******************************************
         * Scenario 4: Displays patterns over time
         * when data is available
         ******************************************/
        isPatternsOverTime &&
          subQuestionTableData !== null &&
          hasTableData(subQuestionTableData) && (
            <div id={`${location.LocationType}-table`}>
              <SubQuestionTable
                isScrollToTop={false}
                parentLocation={location}
                locationAbbr={locationAbbr}
                subQuestionId={subQuestionId}
                questionId={questionId}
                year={year}
                linkFirstColumn={true}
                tableName={`${location.LocationDesc} Survey Results`}
                question={question}
                title={"scenario 3"}
                subQuestion={subQuestion}
                subQuestionTableData={subQuestionTableData}
                allLocations={allLocations}
                comparisonData={comparisonData}
                onLocationChange={onLocationChange}
                fetchTableDataForLocation={fetchTableDataForLocation}
                onSubQuestionChange={onSubQuestionChange}
                isPatternsOverTime={isPatternsOverTime}
              />
            </div>
          )
      }
      {
        /******************************************
         * Scenario 5: Displays "no data" message
         * when "isPatternsOverTime" === true
         ******************************************/
        isPatternsOverTime && !hasTableData(subQuestionTableData) && (
          <div style={{ height: 100 }}>No data for selected location.</div>
        )
      }
      {
        /******************************************
         * Scenario 5: Displays "no data" message
         * when "isPatternsOverTime" === false
         ******************************************/
        selectedData.location.LocationAbbr !== "ALL" &&
          subQuestionTableData === null &&
          locationSelectedFromDropdownMenu &&
          !isPatternsOverTime && (
            <div style={{ height: 100 }}>No data for selected location.</div>
          )
      }
    </>
  );
};

export default Table;
