import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import SubQuestion from "./SubQuestion";

const SubQuestions = ({
  handleSubQuestionChange,
  question,
  subQuestions,
  subQuestionId,
  vizType,
}) => {
  return (
    <div>
      <div className="row" id="subTables">
        {question &&
          question.hasOwnProperty("SubQuestions") &&
          question.SubQuestions.length > 0 && (
            <div className="col-12 mt-3 mb-3">
              Select a subquestion to generate {vizType.toLowerCase()}
            </div>
          )}
        <div className="col-12">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1000: 3, 1200: 4 }}
          >
            <Masonry columnsCount={4} gutter="8px">
              {subQuestions &&
                subQuestions.length > 0 &&
                subQuestions.map((sq, i) => (
                  <SubQuestion
                    subQuestion={sq}
                    handleSubQuestionChange={handleSubQuestionChange}
                    isSelected={sq.SubQuestionId === subQuestionId}
                    question={question}
                  />
                ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </div>
  );
};

export default SubQuestions;
